<script>
import OriginalComponent from "vuetify/es5/components/VRadioGroup/VRadioGroup.js";

export default {
  name: "optix-radio-group",
  extends: OriginalComponent,
  beforeCreate: function() {
    this.$delete(this.$options.props, "color");
  },
  computed: {
    color() {
      return "optix-option-accent-button";
    },
    classes: function classes() {
      return {
        "v-input--radio-group--column": this.column && !this.row,
        "v-input--radio-group--row": this.row,
      };
    },
  },
};
</script>
