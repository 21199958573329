import "@babel/polyfill";
import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/optix";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";
import "./styles/base.styl";
import "promise-polyfill/src/polyfill.js";
import OptixEnv from "./services/OptixEnv.js";

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: OptixEnv.getVar("google_analytics"),
  router,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
