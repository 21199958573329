<template>
  <div>
    <v-layout column class="canvas">
      <div v-if="addedPaymentMethod">
        <div class="added-block">
          <v-icon>check_circle</v-icon>Bank account successfully added
        </div>
        <a @click="cleanup()">Switch payment method</a>
      </div>
      <div v-else-if="waiting" style="text-align: center">
        <v-progress-circular
          indeterminate
          class="mb-3 mt-2"
        ></v-progress-circular
        ><br />
        Waiting for completion in a new tab.
        <a :href="pm.url" target="_blank">Re-open tab</a>
      </div>
      <div v-else>
        Complete this process securely in a new tab<br />
        <optix-btn
          @click="waitForPaymentMethod(pm.url)"
          style="left: -6px; position: relative"
          >Connect account</optix-btn
        >
      </div>
    </v-layout>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";

export default {
  mixins: [Validate],
  name: "optix-additional-payment-method",
  props: {
    pm: {
      default: null,
    },
  },
  data() {
    return {
      waiting: false,
      intervalWatcher: null,
      initialPaymentMethodsCount: -1,
      currentPaymentMethods: [],
      addedPaymentMethod: null,
    };
  },
  unmounted() {
    this.stopWatching();
  },
  methods: {
    cleanup() {
      this.addedPaymentMethod = null;
      this.$emit("input", null);
    },
    stopWatching() {
      if (this.intervalWatcher) {
        clearInterval(this.intervalWatcher);
        this.intervalWatcher = null;
      }
    },
    async checkPaymentMethod() {
      let pm_response = await this.$optixWS.call(
        "GET",
        "/payments/getPaymentMethods/",
        {
          params: {
            venue_id: this.$optixConfiguration.venue.venue_id,
            member_id: this.$optixConfiguration.user.lookup.member_account,
          },
        }
      );
      let pm = pm_response.data.response.payment_methods;
      if (this.initialPaymentMethodsCount == -1) {
        this.initialPaymentMethodsCount = pm.length;
        this.currentPaymentMethods = pm.map((x) => x.pm_id);
      } else if (this.initialPaymentMethodsCount != pm.length) {
        this.addedPaymentMethod = pm.filter(
          (x) => !this.currentPaymentMethods.includes(x.pm_id)
        )[0];
        this.waiting = false;
        this.stopWatching();
        this.$emit("input", this.addedPaymentMethod);
      }
    },
    waitForPaymentMethod(url) {
      this.waiting = true;
      this.stopWatching();
      this.intervalWatcher = setInterval(this.checkPaymentMethod, 5000);
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="stylus" scoped>
.canvas {
  width: 100%;
  justify-content: center;
  align-content: flex-start;
  text-align:left;
  margin-bottom: 12px;
  padding-bottom: 4px;
}
.added-block {
  border-style: solid;
  border-width: 1px;
  width: 330px;
  border-color: var(--optix-theme-defaultFontColor);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 4px;
}
.added-block .v-icon {
  margin-right: 12px;
}
</style>
