<script>
import OriginalComponent from "vuetify/es5/components/VBtn/VBtn.js";

export default {
  name: "optix-btn",
  extends: OriginalComponent,
  props: {
    forceDark: {
      type: Boolean,
      default: null,
    },
    disabledStyle: {
      type: Boolean,
      default: null,
    },
  },
  beforeCreate: function() {
    this.$delete(this.$options.props, "color");
    this.$delete(this.$options.props, "dark");
    this.$delete(this.$options.props, "light");
    this.$delete(this.$options.props, "depressed");
    // Disabled is not valid option due to extension conflicts, use disabledStyle prop instead
    this.$delete(this.$options.props, "disabled");
  },
  methods: {
    // Click
    click: function click(e) {
      if (this.disabledStyle) {
        return; // Ignore click if disabledStyle
      }
      !this.fab && e.detail && this.$el.blur();
      this.$emit("click", e);
    },
  },
  computed: {
    color() {
      let classes = [];
      if (!this.icon) {
        if (this.flat) {
          classes.push("optix-option-accent-button");
        }
        classes.push("optix-option-accent-button-bg");
      }
      if (this.disabledStyle) {
        classes.push("disabled-element");
      }
      return classes.join(" ");
    },
    dark() {
      if (this.forceDark != null) {
        return this.forceDark;
      }
      return this.$optixConfiguration.options.colorOnAccent != "dark";
    },
    light() {
      if (this.forceDark != null) {
        return !this.forceDark;
      }
      return this.$optixConfiguration.options.colorOnAccent == "dark";
    },
    depressed() {
      return (
        this.$optixConfiguration.options.raisedButtons == "0" ||
        this.$optixConfiguration.options.raisedButtons == false
      );
    },
  },
};
</script>
