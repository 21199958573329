<script>
import OriginalComponent from "vuetify/es5/components/VForm/VForm.js";

export default {
  name: "optix-form",
  extends: OriginalComponent,
  props: {},
  methods: {
    focusErrors(element) {
      // Due to many focus issues on iOS mobile version, we added this iPhone user message
      if (window.navigator.userAgent.match(/iPad|iPhone|iPod/)) {
        this.$optixConfiguration.showMessage(
          "There are some errors, please check all the red fields.",
          "error"
        );
      }
      this.$nextTick(() => {
        this.focusSubelementsErrors(element);
      });
    },
    focusSubelementsErrors(element) {
      let children = [];
      if (element) {
        children = element.$children;
      } else {
        children = this.$children;
      }
      return children.some((vc) => {
        if (
          vc.errorMessages &&
          ((typeof vc.errorMessages == "string" && vc.errorMessages != "") ||
            (typeof vc.errorMessages == "object" &&
              vc.errorMessages.length &&
              vc.errorMessages[0] != ""))
        ) {
          if (vc) {
            this.$nextTick(() => {
              vc.focus();
            });
            return true;
          }
          return false;
        } else if (vc.$children) {
          return this.focusSubelementsErrors(vc);
        }
        return false;
      });
    },
  },
};
</script>
