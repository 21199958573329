<template>
  <div>
    <optix-header>
      Reset password
    </optix-header>
    <optix-container>
      <optix-content-layout v-if="sendingRecoveryCode">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else>
        <p class="mb-5 mt-2">
          We've emailed a password reset code to
          {{ $optixConfiguration.user.data.email }}. Grab that code and enter it
          here to set a new password.
        </p>
        <optix-form ref="form">
          <optix-text-field
            class="mb-5"
            ref="resetCode"
            label="Reset code"
            name="resetCode"
            id="resetCode"
            v-model="password.resetCode"
            :error-messages="errors.resetCode"
            @keydown.enter.prevent="$refs.password.focus()"
            hint="Enter the code we emailed you"
          />
          <optix-text-field
            label="New password"
            ref="password"
            name="password"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            v-model="password.newPassword"
            @click:append="showPassword = !showPassword"
            :error-messages="errors.newPassword"
            @keydown.enter.prevent="next"
            :hint="
              password.newPassword.length < 8
                ? 'Password must be at least 8 characters'
                : ''
            "
          />
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" @click="back" flat>Back</optix-btn>
      <optix-dot-progress slot="center" />
      <optix-btn
        slot="right"
        @click="next"
        :loading="nextLoading"
        :disabledStyle="sendingRecoveryCode"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";

export default {
  mixins: [Validate],
  data() {
    return {
      sendingRecoveryCode: true,
      nextLoading: false,
      showPassword: false,
      password: {
        resetCode: "",
        newPassword: "",
      },
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.data.email) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    this.$optixWS
      .call(
        "POST",
        "/users/resetPassword/",
        {},
        {
          venue_id: this.$optixConfiguration.venue.venue_id,
          email: this.$optixConfiguration.user.data.email,
        }
      )
      .then((success) => {
        if (success.data.response != "Email sent") {
          this.$optixConfiguration.showSystemError(
            "Recover password error",
            success
          );
        } else {
          this.sendingRecoveryCode = false;
          this.$nextTick(() => {
            this.$refs.resetCode.focus();
          });
        }
      })
      .catch((error) => {
        this.$optixConfiguration.showSystemError(
          "Recover password error",
          error
        );
      });
  },
  watch: {},
  methods: {
    next() {
      this.validateForm(this.password, {
        resetCode: [Validate.rules.resetCode],
        newPassword: [Validate.rules.password],
      })
        .then((success) => {
          this.nextLoading = true;
          this.$optixWS
            .call(
              "POST",
              "/users/resetPassword/",
              {},
              {
                venue_id: this.$optixConfiguration.venue.venue_id,
                password: this.password.newPassword,
                code: this.password.resetCode,
                email: this.$optixConfiguration.user.data.email,
              }
            )
            .then((success) => {
              if (
                typeof success.data.response === "string" &&
                success.data.response != "Password successfully reset"
              ) {
                this.addError("newPassword", success.data.response);
              } else {
                this.$optixConfiguration.autoPassword = this.password.newPassword;
                this.$router.push({
                  name: "Password",
                });
              }
              this.nextLoading = false;
            })
            .catch((error) => {
              this.$optixConfiguration.showSystemError(
                "Recover password error",
                error
              );
              this.nextLoading = false;
              this.$refs.form.focusErrors();
            });
        })
        .catch((e) => {
          this.$refs.form.focusErrors();
        });
    },
    back() {
      this.$router.push({ name: "Lookup" });
    },
  },
};
</script>
