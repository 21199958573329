<template>
  <div>
    <optix-header :showTitle="false">
      <span>Thank you!</span>
    </optix-header>
    <optix-container>
      <optix-content-layout class="complete-boxes">
        <div>
          <img
            v-if="$optixConfiguration.options.darkMode"
            src="@/assets/existing-active-member-dark.svg"
          />
          <img v-else src="@/assets/existing-active-member-light.svg" />
        </div>
        <div class="thank-you">
          <div>
            You already have an account, please contact an administrator
          </div>
        </div>
        <div class="contact">
          <optix-btn
            v-if="contactUsEmail"
            @click="openContact()"
            :outlined="true"
            :monocrome="true"
            >Contact us</optix-btn
          >
        </div>
      </optix-content-layout>
    </optix-container>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import Steps from "@/mixins/Steps.js";

export default {
  mixins: [Validate, Steps],
  data() {
    return {
      member: {},
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }
  },
  methods: {
    openContact() {
      window.open("mailto:" + this.contactUsEmail);
    },
  },
  watch: {},
  computed: {
    isDesktop() {
      return !navigator.userAgent.match(/iPad|iPhone|iPod|Android/);
    },
    isIOS() {
      return navigator.userAgent.match(/iPad|iPhone|iPod/);
    },
    isAndroid() {
      return navigator.userAgent.match(/Android/);
    },
    contactUsEmail() {
      if (this.$optixConfiguration.venue.contact_email) {
        return this.$optixConfiguration.venue.contact_email;
      }
      return false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.complete-boxes>div>div {
  padding-bottom: 35px;
  text-align: center;
}

.complete-boxes>div>div:last-child {
  padding-bottom: 0px;
}

.complete-boxes>div>div:first-child {
  padding-top: 10px;
}

.phone {
  text-align: left;
}

.thank-you {
  display: flex;
  justify-content: center;
}

.thank-you>div {
  max-width: 360px;
}

h4.center {
  text-align: center;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .play-store {
    padding-left: 12px;
    padding-right: 12px;
  }

  .app-store {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>
