<template>
  <div>
    <optix-header>
      Debug Control Panel
    </optix-header>
    <optix-container>
      <optix-content-layout>
        <v-flex>
          <h3>Current options</h3>
          <pre>{{ $optixConfiguration.options }}</pre>
        </v-flex>
        <v-flex>
          <h3>Current Query Args</h3>
          <pre>{{ $optixConfiguration.queryStringVars }}</pre>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.backgroundColor"
            label="backgroundColor"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-select
            :items="[
              { value: true, text: 'Enabled' },
              { value: false, text: 'Disabled' },
            ]"
            label="darkMode"
            v-model="$optixConfiguration.options.darkMode"
          ></optix-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.accentColor"
            label="accentColor"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-select
            :items="[
              { value: 'dark', text: 'Dark' },
              { value: 'light', text: 'Light' },
            ]"
            label="colorOnAccent"
            v-model="$optixConfiguration.options.colorOnAccent"
          ></optix-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.navBarColor"
            label="navBarColor"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-select
            :items="[
              { value: 'dark', text: 'Dark' },
              { value: 'light', text: 'Light' },
            ]"
            label="colorOnNavBar"
            v-model="$optixConfiguration.options.colorOnNavBar"
          ></optix-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.fontFamily"
            label="fontFamily"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-select
            :items="[
              { value: 'regular', text: 'Regular' },
              { value: 'solo', text: 'Solo' },
              { value: 'box', text: 'Box' },
              { value: 'outline', text: 'Outline' },
            ]"
            label="formInputStyle"
            v-model="$optixConfiguration.options.formInputStyle"
          ></optix-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.navBarShadow"
            label="navBarShadow"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-text-field
            v-model="$optixConfiguration.options.buttonCornerRadius"
            label="buttonCornerRadius"
          ></optix-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <optix-select
            :items="[
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]"
            label="raisedButtons"
            v-model="$optixConfiguration.options.raisedButtons"
          ></optix-select>
        </v-flex>
        <v-flex>
          <h3>User data</h3>
          <pre style="overflow-x:auto;width:100%;">{{
            $optixConfiguration.user
          }}</pre>
        </v-flex>
        <v-flex>
          <h3>Venue data</h3>
          <pre
            style="overflow-x:auto; overflow-y:auto; width:100%;max-height: 300px"
            >{{ $optixConfiguration.venue }}</pre
          >
        </v-flex>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-dot-progress slot="center" />
      <optix-btn @click="$router.back()" slot="right">Back</optix-btn>
    </optix-footer-navigation>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color1: "#ff0000",
      outline: false,
    };
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
