<template>
  <v-container
    ref="header"
    fluid
    class="header"
    :class="{
      'toolbar-mode': mode === 'toolbar',
      'standalone-mode': mode === 'standalone',
    }"
  >
    <div class="header-inner">
      <template v-if="mode === 'toolbar'">
        <v-toolbar :height="64" :class="baseClass" color="optix-option-navBar">
          <optix-btn
            v-if="this.$optixConfiguration.queryStringVars.closeable"
            icon
            @click="closeOptixModal()"
            :ripple="false"
          >
            <v-icon>close</v-icon>
          </optix-btn>
          <img v-else :src="$optixConfiguration.venue.logo" />
          <v-toolbar-title><slot /></v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <slot name="toggle" />
      </template>
      <v-layout
        v-else
        align-center
        :class="baseClass"
        class="optix-option-background standalone layoutpadding"
      >
        <v-flex xs12 class="v-toolbar__title box pa-2 bottom-divider">
          <div class="logo">
            <img :src="$optixConfiguration.venue.logo" />
            <div>{{ $optixConfiguration.venue.name }}</div>
          </div>
          <div class="title-box" v-if="showTitle">
            <slot />
            <slot name="toggle" />
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "optix-header",
  data() {
    return {
      windowWidth: 0,
    };
  },
  props: {
    forceCloseable: { default: null },
    showTitle: { default: true },
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });

    this.$refs.header.addEventListener("touchmove", (event) => {
      event.preventDefault();
      return false;
    });
    window.document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
    });
    window.document.addEventListener(
      "touchmove",
      function(event) {
        event = event.originalEvent || event;
        if (event.scale > 1) {
          event.preventDefault();
        }
      },
      false
    );
  },
  computed: {
    mode() {
      if (
        this.$optixConfiguration.queryStringVars.closeable ||
        this.$optixConfiguration.queryStringVars.embedded ||
        this.forceCloseable ||
        this.windowWidth <= 750
      ) {
        return "toolbar";
      }
      return "standalone";
    },
    baseClass() {
      let shadowLevel = this.$optixConfiguration.options.navBarShadow
        .toString()
        .replace(/px/, "");
      if (parseFloat(shadowLevel) > 0) {
        return "elevation-" + shadowLevel;
      } else {
        return (
          "elevation-0" + (this.mode == "toolbar" ? " bottom-divider" : "")
        );
      }
    },
  },
  methods: {
    closeOptixModal() {
      window.parent.postMessage("closeOptixModal", "*");
    },
  },
};
</script>
