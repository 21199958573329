import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import OptixConfiguration from "@/services/OptixConfiguration.js";

// Import all ui optix components
const requireComponent = require.context(
  "@/ui/components", // The relative path of the components folder
  true, // Whether or not to look in subfolders
  /.*\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.use(OptixConfiguration, {});
