<template>
  <v-app class="optix-app" :dark="$optixConfiguration.options.darkMode">
    <div class="optix-widget-preloader" v-if="loading && !error">
      <svg
        class="spinner"
        width="40px"
        height="40px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="circle"
          fill="none"
          stroke-width="4"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
    <div
      v-if="!loading && !error"
      class="main-container optix-fadein"
      :class="{ light: $optixConfiguration.options.darkMode }"
    >
      <div
        :class="{
          'left-container': true,
          'left-container-inquiry': isInquiry,
          'left-container-inquiry-embedded': isEmbeddedOrPopUp,
        }"
        @click="debugRequest()"
      >
        <router-view />
      </div>
      <div v-if="!isInquiry" class="right-container">
        <div
          class="slideshow optix-option-background"
          v-if="slideshowImages.length"
        >
          <div
            v-for="(item, i) in slideshowImages"
            :key="i"
            :style="{
              'background-image':
                'url(' + (item.url ? item.url : item.data) + ')',
              opacity: i == slideshowImageCounter ? 1 : 0,
            }"
          ></div>
        </div>
        <!--div class="overlay">
          <blockquote>
            Here has been the most rewarding experience in my career to date! The atmosphere is really awesome.
            <footer>
              <small>
                <em>&mdash;John Johnson at venue {{$optixConfiguration.venue.address}}</em>
              </small>
            </footer>
          </blockquote>
        </div>
        <div class="debug-hidden-button" @click="debugRequest()">
        </div-->
      </div>
    </div>
    <div
      v-if="error"
      class="main-container optix-fadein"
      :class="{ light: $optixConfiguration.options.darkMode }"
    >
      <div class="left-container error-container" @click="debugRequest()">
        <optix-header :forceCloseable="true">
          Unable to load form
        </optix-header>
        <div class="error-block">
          <div class="icon">
            <img
              v-if="$optixConfiguration.options.darkMode"
              src="@/assets/onboarding-error-dark.svg"
            />
            <img v-else src="@/assets/onboarding-error-light.svg" />
          </div>
          <h4 class="message">
            {{ formErrorMessage }}
          </h4>
          <div class="button">
            <optix-btn @click="loadVenue()">
              Reload
            </optix-btn>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="$optixConfiguration.snackbar.open"
      :color="$optixConfiguration.snackbar.type"
      :multi-line="true"
      :timeout="0"
      top
      auto-height
    >
      <span v-html="$optixConfiguration.snackbar.messages.join('<br/>')"></span>
      <v-btn dark flat @click="closeSnackbar()">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import qs from "qs";

// Replacement images for right panel
import img1 from "@/assets/default-webusersignup6.jpg";
import img2 from "@/assets/default-webusersignup7.jpg";
import img3 from "@/assets/default-webusersignup8.jpg";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      debugRequestCounter: 0,
      slideshowImageCounter: 0,
      debugMode: false,
      loadVenueCounter: 0,
    };
  },
  mounted() {
    this.loadVenue();

    // Listen for incoming javascript login actions
    window.addEventListener("message", (message) => {
      try {
        switch (message.data.command) {
          case "loginAs":
            this.$optixConfiguration.user.lookup = message.data.info.lookup;
            this.$optixConfiguration.user.data = message.data.info.user;
            this.$optixWS.access_token = message.data.info.access_token;
            this.$optixConfiguration.user.access_token =
              message.data.info.access_token;
            if (this.$optixConfiguration.user.lookup.member_status != 1) {
              this.$router.push({ name: "Profile" });
            } else {
              // Its a member? Go to last interface...
              this.$router.push({ name: "Complete" });
            }
        }
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    slideshowImages(newValue, oldValue) {
      // This solves problems related to theme editor flickering
      if (oldValue && oldValue.length > 0) {
        let newSum = newValue
          .map((val) => val.url || val.data)
          .reduce((total, value) => `${total}${value}`, "");
        let oldSum = oldValue
          .map((val) => val.url || val.data)
          .reduce((total, value) => `${total}${value}`, "");
        if (newSum != oldSum) {
          this.nextSlideshowImage();
        }
      } else {
        this.nextSlideshowImage();
      }
    },
  },
  computed: {
    slideshowImages() {
      if (
        this.$optixConfiguration.options.images &&
        this.$optixConfiguration.options.images.length
      ) {
        return this.$optixConfiguration.options.images;
      }
      return [{ data: img1 }, { data: img2 }, { data: img3 }];
    },
    formErrorMessage() {
      return this.debugMode
        ? this.errorMessage
        : this.loadVenueCounter > 1
        ? `Unfortunately we're still having problems loading the form. Please try again later.`
        : `We'd love for you to get signed up, but unfortunately there's a problem right now loading the form.`;
    },
    isInquiry() {
      return window.location.href.includes("inquiry");
    },
    isEmbeddedOrPopUp() {
      const queryStringVars = qs.parse(window.location.search.substring(1));

      return queryStringVars.closeable || queryStringVars.embedded;
    },
  },
  methods: {
    closeSnackbar() {
      this.$optixConfiguration.snackbar.open = false;
      this.$optixConfiguration.snackbar.messages = [];
    },
    loadVenue() {
      this.loadVenueCounter++;
      this.loading = true;
      this.error = false;
      this.errorMessage = false;
      // Parse get vars
      let queryStringVars = qs.parse(window.location.search.substring(1));

      if (
        navigator.userAgent.match(/iPad|iPhone|iPod/) &&
        (queryStringVars.closeable || queryStringVars.embedded)
      ) {
        document
          .getElementsByTagName("html")[0]
          .classList.add("restrict-height");
      }

      // Send the user to homepage if there is no session stored
      if (
        !this.$optixConfiguration.user.access_token &&
        this.$route.path != "/"
      ) {
        this.$router.push("/");
      }

      // Load app configuration by server
      this.$optixConfiguration
        .loadOptions(queryStringVars)
        .then((ready) => {
          setTimeout(() => {
            window.parent.postMessage("optixModalLoaded", "*");
            this.loading = false;
            this.nextSlideshowImage();
          }, 10);
          setInterval(this.nextSlideshowImage, 30000); // 30 seconds, improve this in the future
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        });
    },
    // After 5 clicks on top left corner, 2 clicks on right top corner and one extra click on left top corner, opens the debug interface.
    debugRequest() {
      let e = window.event;
      if (e.clientY < 100 && e.clientX < 100 && this.debugRequestCounter < 5) {
        this.debugRequestCounter++;
      } else if (
        e.clientY < 100 &&
        e.clientX > 100 &&
        this.debugRequestCounter < 7 &&
        this.debugRequestCounter >= 5
      ) {
        this.debugRequestCounter++;
      } else if (
        e.clientY < 100 &&
        e.clientX < 100 &&
        this.debugRequestCounter >= 7
      ) {
        this.debugRequestCounter++;
      } else {
        this.debugRequestCounter = 0;
      }
      if (this.debugRequestCounter >= 8) {
        this.$router.push("/ControlPanel");
        this.debugMode = true;
      }
    },
    // Change to next slideshow
    nextSlideshowImage() {
      if (this.slideshowImages.length >= 1) {
        this.slideshowImageCounter =
          (this.slideshowImageCounter + 1) % this.slideshowImages.length;
      } else {
        this.slideshowImageCounter = 0;
      }
    },
  },
};
</script>
