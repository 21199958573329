<script>
import OriginalComponent from "vuetify/es5/components/VSelect/VSelect.js";

export default {
  name: "optix-select",
  extends: OriginalComponent,
  beforeCreate: function() {
    this.$delete(this.$options.props, "color");
    this.$delete(this.$options.props, "dark");
    this.$delete(this.$options.props, "light");
    this.$delete(this.$options.props, "box");
    this.$delete(this.$options.props, "outline");
    this.$delete(this.$options.props, "solo");
  },
  computed: {
    color() {
      return "optix-option-accent";
    },
    dark() {
      return this.$optixConfiguration.options.darkMode == "dark";
    },
    box() {
      return this.$optixConfiguration.options.formInputStyle == "box";
    },
    outline() {
      return this.$optixConfiguration.options.formInputStyle == "outline";
    },
    solo() {
      return this.$optixConfiguration.options.formInputStyle == "solo";
    },
  },
};
</script>
