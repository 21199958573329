<script>
import OriginalComponent from "vuetify/es5/components/VCheckbox/VCheckbox.js";

export default {
  name: "optix-checkbox",
  extends: OriginalComponent,
  beforeCreate: function() {
    this.$delete(this.$options.props, "color");
  },
  computed: {
    color() {
      return "optix-option-accent-button";
    },
  },
};
</script>
