import axios from "axios";

export default {
  access_token: "",
  api_url: "",
  api2_url: "",

  // Call a method using the token stored
  call: function(method, to, user_config, payload) {
    let headers = {
      "X-Accept-Status-Code": 1,
    };
    if (this.access_token != "") {
      headers["access_token"] = this.access_token;
    }
    let config = {
      method: method,
      url: this.api_url + to,
      data: payload,
      headers: headers,
    };
    if (user_config) {
      config = Object.assign(config, user_config);
    }
    return new Promise((resolve, reject) => {
      axios(config)
        .then((m) => {
          resolve(m);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  // Call a method using the token stored
  graphQL: async function(query, variables, operationName, user_config) {
    let headers = {};
    if (this.access_token && this.access_token != "") {
      headers["Authorization"] = "Bearer " + this.access_token;
    }
    let config = {
      method: "POST",
      url: this.api2_url + "/graphql",
      data: {
        query,
        variables,
        operationName,
      },
      headers: headers,
    };
    if (user_config) {
      config = Object.assign(config, user_config);
    }
    return await axios(config);
  },
  trackError: function(error, payload) {
    let config = {
      method: "POST",
      url: this.api_url + "/logger/",
      data: {
        platform: "vue-member-widget",
        error: error,
        details: payload,
      },
    };
    axios(config)
      .then((m) => {
        // OK
      })
      .catch((e) => {
        // OK ?
      });
  },
};
