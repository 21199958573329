<template>
  <div>
    <template v-if="isInquiryMode">
      <optix-header class="inquiry">
        What are you interested in?
        <ItemSelector
          v-model="listType"
          value="b"
          :items="[
            { text: 'Plans', value: 'PLAN_TEMPLATES' },
            { text: 'Passes', value: 'PASSES' },
            { text: 'Other', value: 'OTHER' },
          ]"
          class="item-selector"
          full-width
        />
      </optix-header>
    </template>
    <template v-else>
      <optix-header>
        Select an option
        <template #toggle>
          <div class="list-selector">
            <v-btn-toggle
              v-if="v2Plans.length && v2Products.length"
              v-model="listType"
              class="optix-option-background"
              mandatory
            >
              <v-btn value="ALL">All</v-btn>
              <v-btn value="PLAN_TEMPLATES">Plans</v-btn>
              <v-btn value="PASSES">Passes</v-btn>
            </v-btn-toggle>
          </div>
        </template>
      </optix-header>
    </template>
    <optix-container>
      <optix-content-layout v-if="isLoading">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else :noHorizontalBorders="true">
        <v-layout align-center fill-height>
          <v-flex xs12 fill-height align-center justify-center>
            <v-radio-group
              v-model="$optixConfiguration.signupData.selectedItemCode"
              class="plan-radio-group"
              v-if="
                (listType !== 'OTHER' && listType !== 'ALL') || !isInquiryMode
              "
            >
              <div
                v-for="item in plansAndProducts"
                class="plan-card bottom-divider py-5"
                :key="item.code"
                @click.stop="selectItem(item)"
              >
                <h2 v-if="item.list_header">{{ item.list_header }}</h2>
                <v-layout row>
                  <v-flex>
                    <optix-radio :value="item.code" />
                  </v-flex>
                  <v-flex xs12>
                    <v-layout row>
                      <v-flex>
                        <h3 class="plan-name">{{ item.name }}</h3>
                      </v-flex>
                      <v-flex>
                        <div class="plan-price text-xs-right">
                          <span
                            class="the-price optix-option-accent--text"
                            v-if="item.price == 0"
                            >Free</span
                          >
                          <span
                            class="the-price optix-option-accent--text"
                            v-if="item.price > 0"
                            >{{ $optixConfiguration.venue.currency_symbol
                            }}{{
                              item.price.toString().replace(/\.00$/, "")
                            }}</span
                          >
                          <span
                            class="the-period"
                            v-if="!item.product_id && item.price > 0"
                            >{{
                              getPeriodShortName(item.price_frequency)
                            }}</span
                          >
                        </div>
                      </v-flex>
                    </v-layout>

                    <div
                      class="plan-description mt-3 grey--text text--darken-1"
                    >
                      {{ item.description }}
                    </div>

                    <div class="plan-access mt-3">
                      <ol>
                        <li
                          v-for="(access, index) in item.accesses"
                          class="plan-access grey--text text--darken-1"
                          :key="index"
                        >
                          <div class="plan-icon"><v-icon>done</v-icon></div>
                          <span>{{ access.description }}</span>
                        </li>
                      </ol>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-radio-group>
            <div v-else-if="listType === 'OTHER'">
              <optix-textarea
                @input="setCustomInquiry"
                class="mt-5"
                label="Let us know what you are looking for"
              />
            </div>
          </v-flex>
        </v-layout>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" @click="back" flat>Back</optix-btn>
      <optix-dot-progress slot="center" />
      <optix-btn
        slot="right"
        @click="next"
        :loading="nextLoading"
        :disabledStyle="nextIsDisabled"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import ItemSelector from "@/ui/components/OptixItemSelector.vue";
import Steps from "@/mixins/Steps.js";

export default {
  mixins: [Steps],
  components: { ItemSelector },
  data: () => ({
    nextLoading: false,
    listType: "ALL",
    v2Plans: [],
    v2Products: [],
    customInquiry: null,
  }),
  computed: {
    plansQueryParameter() {
      return this.$optixConfiguration.queryStringVars.plans;
    },
    productsQueryParameter() {
      return this.$optixConfiguration.queryStringVars.products;
    },
    orderQueryParameter() {
      return this.$optixConfiguration.queryStringVars.order
        ? this.$optixConfiguration.queryStringVars.order
            .split(",")
            .map((id) => parseInt(id, 10))
        : [];
    },
    restrictedToPlanLocations() {
      if (this.plansQueryParameter || this.productsQueryParameter) {
        return null;
      }
      return this.$optixConfiguration.member.data.primary_location_id || null;
    },
    canSkip() {
      return this.$optixConfiguration.venue.membership.require_member_plan == 0;
    },
    isLoading() {
      return (
        !this.plansAndProducts.length ||
        (this.canSkip && this.plansAndProducts.length === 1)
      );
    },
    plans() {
      // TODO: stop using venue.plans
      const plans = this.$optixConfiguration.venue.plans;
      const publicPlans = plans.filter((plan) => plan.private == 0);

      const filterIDs = this.plansQueryParameter
        ? this.plansQueryParameter.split(",").map((id) => parseInt(id, 10))
        : [];
      const filteredPlans = filterIDs.length
        ? plans.filter((plan) => filterIDs.includes(parseInt(plan.plan_id, 10)))
        : [];

      if (filteredPlans.length) {
        // Preserve the order
        return filteredPlans.sort((a, b) => {
          return (
            filterIDs.indexOf(parseInt(a.plan_id, 10)) -
            filterIDs.indexOf(parseInt(b.plan_id, 10))
          );
        });
      } else {
        // Let the backend decide the order
        return publicPlans;
      }
    },
    plansAndProducts() {
      if (this.v2Plans.length) {
        this.v2Plans[0].list_header = "Recurring plans";
      }
      if (this.v2Products.length) {
        this.v2Products[0].list_header = "One-time passes";
      }

      const all = this.v2Plans.concat(this.v2Products).map((item) => {
        item.price = item.product_id ? item.unit_amount : item.price;
        item.code = item.plan_template_id
          ? `plan-${item.plan_template_id}`
          : `product-${item.product_id}`;
        return item;
      });

      const planFilterIDs = this.plansQueryParameter
        ? this.plansQueryParameter.split(",").map((id) => parseInt(id, 10))
        : [];
      const productFilterIDs = this.productsQueryParameter
        ? this.productsQueryParameter.split(",").map((id) => parseInt(id, 10))
        : [];

      const filtered =
        planFilterIDs.length || productFilterIDs.length
          ? all.filter((planOrProduct) => {
              if (planOrProduct.product_id) {
                return productFilterIDs.includes(
                  parseInt(planOrProduct.product_id, 10)
                );
              } else {
                return planFilterIDs.includes(
                  parseInt(planOrProduct.plan_template_id, 10)
                );
              }
            })
          : [];
      let list = [];
      if (filtered.length) {
        // Preserve the order
        list = filtered
          .sort((a, b) => {
            return (
              this.orderQueryParameter.indexOf(
                parseInt(a.product_id || a.plan_template_id, 10)
              ) -
              this.orderQueryParameter.indexOf(
                parseInt(b.product_id || b.plan_template_id, 10)
              )
            );
          })
          .map((a) => {
            delete a.list_header;
            return a;
          });
      } else {
        // Let the backend decide the order
        list = all;
      }

      // Prepend the skip option if allowed
      if (this.canSkip) {
        all.unshift({
          price: 0,
          code: "skip",
          product_id: 0,
          plan_template_id: 0,
          name: "No Plan or Pass",
          description: "Choose an option later",
        });
      }

      return list.filter((a) => this.showItem(a));
    },
    nextIsDisabled() {
      return (
        !this.$optixConfiguration.signupData.selectedPlanId &&
        !this.$optixConfiguration.signupData.selectedItemCode &&
        !this.customInquiry
      );
    },
  },
  async mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    let member_status = await this.$optixWS.call(
      "POST",
      "/members/mobile/lookup/",
      {},
      {
        venue_id: this.$optixConfiguration.venue.venue_id,
        email: this.$optixConfiguration.user.data.email,
        member_type: "onboarding_check",
      }
    );

    if (member_status.data.response.member_status == "1") {
      this.$optixConfiguration.showMessage(
        "You are already a member of this venue, enter your email address to continue to download your app, or enter a new email address to sign up again",
        "info",
        10000
      );
      this.$router.push({ name: "Lookup" });
    }

    let plans = this.$optixWS.graphQL(
      `query plans($organization_id: ID!, $limit: Int, $location_id: [ID!]) {
        planTemplates(
          organization_id: $organization_id
          limit: $limit
          onboarding_enabled: true
          location_id: $location_id
        ) {
          data {
            plan_template_id
            name
            description
            allowance_renewal_frequency
            price
            price_frequency
            deposit
            set_up_fee
            default_end_date
            default_end_date_days
            free_trial_days
            accesses {
              description
            }
          }
        }
      }`,
      {
        limit: 1000,
        organization_id: this.$optixConfiguration.venue.venue_id,
        location_id: this.restrictedToPlanLocations,
      }
    );

    let products = this.$optixWS.graphQL(
      `query products($organization_id: ID!, $limit: Int, $location_id: [ID!]) {
        products(
          organization_id: $organization_id
          limit: $limit
          onboarding_enabled: true
          type: PASS
          location_id: $location_id
        ) {
          data {
            product_id
            name
            description
            unit_amount
            accesses {
              description
            }
          }
        }
      }`,
      {
        limit: 1000,
        organization_id: this.$optixConfiguration.venue.venue_id,
        location_id: this.restrictedToPlanLocations,
      }
    );

    let response = await Promise.all([plans, products]);
    this.v2Plans = response[0].data.data.planTemplates.data;
    this.v2Products = response[1].data.data.products.data;

    if (this.plansAndProducts.length == 1) {
      this.selectItem(this.plansAndProducts[0]);
      this.$nextTick(() => {
        this.$router.push({ name: "PlanOptions" });
      });
    }
    if (this.v2Plans.length + this.v2Products.length == 0) {
      this.skip();
    }
  },
  watch: {
    listType(newValue) {
      if (
        this.$optixConfiguration.signupData.selectedItem &&
        ((newValue === "PLAN_TEMPLATES" &&
          this.$optixConfiguration.signupData.selectedItem.product_id) ||
          (newValue === "PASSES" &&
            this.$optixConfiguration.signupData.selectedItem.plan_template_id))
      ) {
        this.$optixConfiguration.signupData.selectedItem = null;
        this.$optixConfiguration.signupData.selectedItemCode = null;
      }

      if (newValue === "OTHER") {
        this.$optixConfiguration.signupData.selectedItem = null;
        this.$optixConfiguration.signupData.selectedItemCode = null;
      } else {
        this.$optixConfiguration.signupData.customInquiry = null;
      }
    },
  },
  methods: {
    showItem(item) {
      return (
        item.code === "skip" ||
        this.listType === "ALL" ||
        this.listType === "OTHER" ||
        (this.listType === "PLAN_TEMPLATES" && item.plan_template_id) ||
        (this.listType === "PASSES" && item.product_id)
      );
    },
    selectItem(item) {
      this.$optixConfiguration.signupData.selectedItem = item;
      this.$optixConfiguration.signupData.selectedItemCode = item.code;
    },
    next() {
      // If skip was selected skip steps
      if (
        (this.$optixConfiguration.signupData.selectedItem &&
          this.$optixConfiguration.signupData.selectedItem.code == "skip") ||
        (this.isInquiryMode && this.listType !== "PLAN_TEMPLATES")
      ) {
        this.skip();
        return;
      }
      this.$router.push({ name: "PlanOptions" });
    },
    skip() {
      if (!this.isInquiryMode) {
        this.$optixConfiguration.signupData.selectedItem = null;
        this.$optixConfiguration.signupData.selectedItemCode = null;
      }
      this.$router.push({ name: "Complete" });
    },
    back() {
      this.$router.push({ name: "Details", query: { back: true } });
    },
    setCustomInquiry(value) {
      this.$optixConfiguration.signupData.customInquiry = value;
      this.customInquiry = value;
    },
  },
};
</script>
<style lang="stylus" scoped>
.header.standalone-mode {
    height: 248px !important;
}
.inquiry {
  margin-bottom: 25px;
  ::v-deep .bottom-divider {
    border: none;
  }
  .item-selector {
    gap: 10px;
    margin-top: 20px;
    height: 96px;
  }
}
.list-selector {
    margin: 18px 0 14px;
    .v-btn-toggle {
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;

        .v-btn {
            background-color: transparent !important;
            border: none;
            box-shadow: none;
            opacity: 0.9;
            padding: 0 18px;
            text-transform: none;

            &:first-child {
                border-radius: 2px 0 0 2px;
            }

            &:last-child {
                border-radius: 0 2px 2px 0;
            }
        }

        .v-btn--active {
            &:before {
                background-color: rgba(0, 0, 0, 0.85);
                color: black;
                opacity: 0.15;
            }
        }

        &.theme--dark {
            border-color: rgba(255, 255, 255, 0.8);

            .v-btn {
                color: rgba(255, 255, 255, 0.9);
            }

            .v-btn--active {
                color: rgba(0, 0, 0, 0.87);
                &:before {
                    background-color: rgba(255, 255, 255, 0.9);
                    color: white;
                    opacity: 0.9;
                }
            }
        }
    }
}

.header.toolbar-mode {
    .list-selector {
        margin: 0;
        .v-btn-toggle {
            width: 100%;
        }
    }
}

.plan-radio-group >>> .v-input__control {
    width: 100%;
}

.plan-card {
    cursor: pointer;

    h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 16px;
    }

    .plan-price {
        .the-price {
            padding-left: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .plan-description {
        font-size: 14px;
    }

    .plan-access {
        font-size: 14px;
        list-style: none;

        ol {
            padding-left: 32px;

            li {
                position: relative;
                padding: 4px 0;

                .plan-icon {
                    display: inline-block;
                    position: absolute;
                    left: -32px;
                }
            }
        }
    }
}

// Remove space from above, unless it's the first card
.plan-card ~ .plan-card h2 {
    margin-top: -30px;
}

.plan-card:last-child {
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.plan-card:first-child {
    padding-top: 12px !important;
}

@media (max-width: 750px) {
  .inquiry {
    margin-bottom: 75px;
    ::v-deep .v-toolbar__title {
      .item-selector {
        position: fixed;
        margin-top: 35px;
        margin-left: -56px;
        .selection-box {
          min-width: 7rem;
        }
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 1100px) {
  .inquiry {
    margin-bottom: 50px;

    ::v-deep .v-toolbar__title {
      .item-selector {
        .selection-box {
          min-width: 7rem;
        }
      }
    }
  }
}
</style>
