<template>
  <div class="card clickable" @click="select">
    <div class="label">
      <v-radio :value="selectedValue" ref="rad" :disabled="disabled" />
      <div class="name-card">{{ name }}</div>
    </div>

    <div v-if="open" class="fields"><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    open: {
      default: false,
    },
    disabled: {
      default: false,
    },
    name: {
      default: "",
    },
    selectedValue: {
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    select() {
      this.$refs.rad.$refs.input.click();
    },
  },
};
</script>
<style scoped>
.card {
  border-style: solid;
  border-width: 2px;
  width: 100%;
  border-color: var(--optix-theme-defaultFontColor);
  border-radius: 4px;
  padding: 16px 16px 16px;
  display: flex;
  flex-direction: column;
  color: var(--optix-theme-defaultFontColor);
}
.card .label {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  align-content: center;
}
.card .v-radio {
  padding: 0px 8px 0px 8px;
  margin-top: 8px !important;
  margin-bottom: 0px !important;
  margin-right: 0px;
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
}
.name-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fields {
  padding: 12px 10px 0px;
  width: 100%;
  position: relative;
  margin-bottom: -14px;
}
.clickable {
  cursor: pointer;
}
</style>
