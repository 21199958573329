<template>
  <vue-recaptcha
    :loadRecaptchaScript="true"
    :sitekey="$optixEnv.getVar('recaptcha_site_key')"
    @expired="onExpire"
    @verify="onVerify"
    ref="vueRecaptcha"
    size="invisible"
  />
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    token: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    execute() {
      this.$refs.vueRecaptcha.execute();
    },
    reset() {
      this.token = null;
      this.$refs.vueRecaptcha.reset();
    },
    onVerify: function(response) {
      this.token = response;
      this.$emit("verify", response);
    },
    onExpire: function() {
      this.reset();
    },
  },
};
</script>

<style scoped>
::v-deep .grecaptcha-badge {
  z-index: 3;
  bottom: 90px !important;

  @media (max-width: 750px) {
    bottom: 75px !important;
  }
}
</style>
