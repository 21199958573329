<template>
  <div>
    <optix-text-field
      v-if="config.type == 'text'"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-textarea
      v-if="config.type == 'textarea'"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-text-field
      v-if="config.type == 'email'"
      type="email"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-text-field
      v-if="config.type == 'tel'"
      type="tel"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-text-field
      v-if="config.type == 'number'"
      type="number"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-date
      v-if="config.type == 'date'"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-select
      v-if="config.type == 'select'"
      :label="config.label + isRequiredMarker"
      v-model="propertyValue"
      :items="config.options"
      item-text="value"
      item-value="property_option_id"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />

    <optix-select
      v-if="config.type == 'multiple'"
      multiple
      :label="config.label + isRequiredMarker"
      :items="config.options"
      item-text="value"
      item-value="property_option_id"
      v-model="propertyValue"
      :placeholder="config.placeholder"
      :hint="config.description"
      :error-messages="propertyError"
    />
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import forEach from "lodash/forEach";

export default {
  name: "optix-property-field",
  data() {
    return {
      propertyValue: null,
      propertyError: [],
    };
  },
  props: {
    config: {
      default: {},
    },
    value: {
      default: null,
    },
  },
  computed: {
    isInquiryMode() {
      return window.location.href.includes("inquiry");
    },
    isRequired() {
      if (this.isInquiryMode) {
        const policyNum = parseInt(this.config.inquiry_display_policy);
        return this.config.inquiry_display_policy == 2;
      }
      return this.config.validation.reduce(
        (previous, validation) =>
          previous ||
          (validation.key == "required" && validation.value == "true"),
        false
      );
    },
    isRequiredMarker() {
      return this.isRequired ? "*" : "";
    },
  },
  mounted() {
    this.propertyValue = this.value;
  },
  watch: {
    value(newValue) {
      this.propertyValue = newValue;
    },
    propertyValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    saveValue() {
      let values = [];
      switch (this.config.type) {
        case "text":
        case "textarea":
        case "email":
        case "tel":
        case "number":
        case "date":
          if (!this.isRequired && this.propertyValue == "") {
            // If empty the webservice returns error, even if not required
            return Promise.resolve(true);
          }
          values = [
            {
              property_id: this.config.property_id,
              reference_id: this.$optixConfiguration.user.lookup.member_account,
              value: this.propertyValue,
            },
          ];
          break;
        case "select":
          values = [
            {
              property_id: this.config.property_id,
              reference_id: this.$optixConfiguration.user.lookup.member_account,
              property_option_id: this.propertyValue,
            },
          ];
          break;
        case "multiple":
          forEach(this.propertyValue, (value) => {
            values.push({
              property_id: this.config.property_id,
              reference_id: this.$optixConfiguration.user.lookup.member_account,
              property_option_id: value,
            });
          });
          break;
      }

      return this.$optixWS
        .call(
          "POST",
          "/properties/saveValue/",
          {},
          {
            property_id: this.config.property_id,
            values,
          }
        )
        .catch((error) => {
          if (
            error.response &&
            error.response.data.response &&
            error.response.data.response.message
          ) {
            this.propertyError = error.response.data.response.message;
            throw new Error();
          } else {
            throw new Error(
              "Please check the information provided and submit again"
            );
          }
        });
    },
    validateField() {
      this.propertyError = [];
      return new Promise((resolve, reject) => {
        if (this.isRequired) {
          switch (this.config.type) {
            case "email":
              this.propertyError = Validate.rules.all([
                Validate.rules.required,
                Validate.rules.email,
              ])(this.propertyValue);
              break;
            case "tel":
              this.propertyError = Validate.rules.all([
                Validate.rules.required,
                Validate.rules.phone,
              ])(this.propertyValue);
              break;
            case "number":
              this.propertyError = Validate.rules.all([
                Validate.rules.required,
                Validate.rules.number,
              ])(this.propertyValue);
              break;
            default:
              this.propertyError = Validate.rules.required(this.propertyValue);
          }
        } else {
          switch (this.config.type) {
            case "email":
              this.propertyError = Validate.rules.optional([
                Validate.rules.email,
              ])(this.propertyValue);
              break;
            case "tel":
              this.propertyError = Validate.rules.optional([
                Validate.rules.phone,
              ])(this.propertyValue);
              break;
            case "number":
              this.propertyError = Validate.rules.optional([
                Validate.rules.number,
              ])(this.propertyValue);
              break;
            /*
            // No specific validation
            case "text":
            case "textarea":
            case "date":
            case "select":
            case "multiple":
            */
          }
        }
        if (this.propertyError.length) {
          reject(this.propertyError);
        } else {
          resolve({});
        }
      });
    },
  },
};
</script>
