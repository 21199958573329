import Vue from "vue";
import Router from "vue-router";
import ControlPanel from "./views/ControlPanel.vue";
import Lookup from "./views/Authentication/Lookup.vue";
import Password from "./views/Authentication/Password.vue";
import RecoverPassword from "./views/Authentication/RecoverPassword.vue";
import BasicInfo from "./views/User/BasicInfo.vue";
import Profile from "./views/User/Profile.vue";
import Details from "./views/User/Details.vue";
import PlanList from "./views/Plan/List.vue";
import PlanOptions from "./views/Plan/Options.vue";
import PayAndConfirmPlansV2 from "./views/Payment/PayAndConfirmPlansV2.vue";
import Complete from "./views/Complete.vue";
import AlreadyMember from "./views/AlreadyMember.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: window.location.href.includes("inquiry") ? "/inquiry/" : "/signup/",
  routes: [
    {
      path: "/ControlPanel",
      name: "ControlPanel",
      component: ControlPanel,
    },
    {
      path: "/complete",
      name: "Complete",
      component: Complete,
    },
    {
      path: "/already-a-member",
      name: "AlreadyMember",
      component: AlreadyMember,
    },
    {
      path: "/confirm",
      name: "PayAndConfirmPlansV2",
      component: PayAndConfirmPlansV2,
    },
    {
      path: "/plan-options",
      name: "PlanOptions",
      component: PlanOptions,
    },
    {
      path: "/plans",
      name: "PlanList",
      component: PlanList,
    },
    {
      path: "/recover-password",
      name: "RecoverPassword",
      component: RecoverPassword,
    },
    {
      path: "/details",
      name: "Details",
      component: Details,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/account-info",
      name: "BasicInfo",
      component: BasicInfo,
    },
    {
      path: "/password",
      name: "Password",
      component: Password,
    },
    {
      path: "/",
      name: "Lookup",
      component: Lookup,
    },
  ],
});
