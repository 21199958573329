var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('optix-header',{attrs:{"showTitle":false}},[(!_vm.loadingData)?_c('span',[_vm._v("Thank you!")]):_vm._e()]),_c('optix-container',[(_vm.loadingData)?_c('optix-content-layout',[_c('optix-loading')],1):_c('optix-content-layout',{staticClass:"complete-boxes"},[_c('div',[(_vm.$optixConfiguration.options.darkMode)?_c('img',{attrs:{"src":require("@/assets/icon-success-dark.svg")}}):_c('img',{attrs:{"src":require("@/assets/icon-success-light.svg")}})]),_c('div',{staticClass:"thank-you"},[_c('div',[_vm._v(_vm._s(_vm.thankyouMessage))])]),(!_vm.isInquiryMode)?[(_vm.isDesktop)?_c('div',[_c('div',{staticClass:"phone"},[_c('h4',{staticClass:"mb-3"},[_vm._v("You're ready to download our app")]),_c('optix-phone-field',{ref:"phone",attrs:{"error-messages":_vm.errors.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('optix-btn',{attrs:{"loading":_vm.sending},on:{"click":function($event){return _vm.sendLink()}}},[_vm._v("\n            Send me a link\n          ")])],1):_c('h4',{staticClass:"mb-5 center"},[_vm._v("You're ready to download our app")]),_c('div',[_c('div',{staticClass:"side-by-side"},[(
                _vm.$optixConfiguration.venue.membership.app_config
                  .itunes_app_page &&
                  (_vm.isDesktop || _vm.isIOS)
              )?_c('a',{staticClass:"app-store",attrs:{"href":_vm.$optixConfiguration.venue.membership.app_config
                  .itunes_app_page,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/app-store-download.png"),"srcset":require("@/assets/app-store-download@2x.png") + " 2x, " + require("@/assets/app-store-download@3x.png") + " 3x"}})]):_vm._e(),(
                _vm.$optixConfiguration.venue.membership.app_config
                  .google_app_page &&
                  (_vm.isDesktop || _vm.isAndroid)
              )?_c('a',{staticClass:"play-store",attrs:{"href":_vm.$optixConfiguration.venue.membership.app_config
                  .google_app_page,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/google-play-download.png"),"srcset":require("@/assets/google-play-download@2x.png") + " 2x, " + require("@/assets/google-play-download@3x.png") + " 3x"}})]):_vm._e()])])]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }