<template>
  <v-layout column>
    <v-flex class="pb-3">
      <p>
        <strong>Note</strong> - You will receive two micro-deposits in your
        bank. Confirm these later in the app to enable this payment method.
      </p>
    </v-flex>
    <optix-text-field
      label="First name"
      v-model="firstName"
      :error-messages="errors.firstName"
    />
    <optix-text-field
      label="Last name"
      v-model="lastName"
      :error-messages="errors.lastName"
    />
    <optix-text-field
      type="number"
      hint="Usually 9 digits. You can find it on the bottom of your check."
      label="Bank routing number"
      v-model="routingNumber"
      pattern="\d*"
      :error-messages="errors.routingNumber"
    />
    <optix-text-field
      type="number"
      hint="Usually 10-12 digits. You can find it after the routing number on your check."
      label="Bank account number"
      v-model="accountNumber"
      pattern="\d*"
      :error-messages="errors.accountNumber"
    />
    <optix-select
      :items="accountTypes"
      label="Account type"
      v-model="accountType"
      :error-messages="errors.accountType"
    />
    <optix-select
      :items="accountHolderTypes"
      label="Account holder type"
      v-model="accountHolderType"
      :error-messages="errors.accountHolderType"
    />
  </v-layout>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import axios from "axios";
import forEach from "lodash/forEach";

export default {
  mixins: [Validate],
  name: "optix-bank-account",
  props: {
    processor: { default: null },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      routingNumber: "",
      accountNumber: "",
      accountType: "",
      accountHolderType: "",
      accountTypes: [
        { text: "Checking", value: "checking" },
        { text: "Savings", value: "savings" },
      ],
      accountHolderTypes: [
        { text: "Personal", value: "personal" },
        { text: "Business", value: "business" },
      ],
    };
  },
  mounted() {
    this.firstName = this.$optixConfiguration.user.data.name;
    this.lastName = this.$optixConfiguration.user.data.surname;
  },
  computed: {},
  methods: {
    validateInfo() {
      return this.validateForm(this, {
        firstName: [Validate.rules.required],
        lastName: [Validate.rules.required],
        routingNumber: [Validate.rules.required, Validate.rules.min(3)],
        accountNumber: [Validate.rules.required, Validate.rules.min(3)],
        accountType: [Validate.rules.required],
        accountHolderType: [Validate.rules.required],
      });
    },
    collectSpreedlyErrors(errors) {
      let generic = [];
      forEach(errors, (error) => {
        switch (error.attribute) {
          case "first_name":
            this.addError("firstName", error.message);
            break;
          case "last_name":
            this.addError("lastName", error.message);
            break;
          case "email":
            this.addError("email", error.message);
            break;
          case "routing_number":
            this.addError("routingNumber", error.message);
            break;
          case "account_number":
            this.addError("accountNumber", error.message);
            break;
          case "account_type":
            this.addError("accountType", error.message);
            break;
          case "account_holder_type":
            this.addError("accountHolderType", error.message);
            break;
          default:
            generic.push(error.message);
        }
      });
      return generic;
    },
    createToken() {
      return new Promise((accept, reject) => {
        if (this.processor == "2") {
          // Spreedly
          const data = {
            payment_method: {
              bank_account: {
                full_name: this.firstName + " " + this.lastName,
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.$optixConfiguration.user.data.email,
                bank_routing_number: this.routingNumber,
                bank_account_number: this.accountNumber,
                bank_account_type: this.accountType,
                bank_account_holder_type: this.accountHolderType,
              },
            },
          };
          const envKey = this.$optixEnv.getVar("spreedly_key");
          let url = `https://core.spreedly.com/v1/payment_methods.json?environment_key=${envKey}`;
          axios
            .post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status == 201) {
                let acceptObject = {
                  venue_id: this.$optixConfiguration.venue.venue_id,
                  member_id: this.$optixConfiguration.user.lookup
                    .member_account,
                  //org_id: window.optix.org_id,
                  processor_id: this.processor,
                  details: response.data.transaction.payment_method,
                  retained: true,
                  type_id: 2,
                };
                this.$emit("input", acceptObject);
                accept(acceptObject);
              } else {
                let messages = this.collectSpreedlyErrors(response.data.errors);
                reject({
                  errors: response.data.errors,
                  messages,
                });
              }
            })
            .catch((error) => {
              let messages = this.collectSpreedlyErrors(
                error.response.data.errors
              );
              reject({
                errors: error.response.data.errors,
                messages,
              });
            });
        } else {
          // ???
          console.log("Wrong bank account processor");
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped></style>
