<template>
  <v-container
    fluid
    class="scrollable optix-option-background"
    justify-center
    align-center
  >
    <slot></slot>
  </v-container>
</template>
<script>
export default {
  name: "optix-container",
  computed: {},
  methods: {},
};
</script>
