<template>
  <div
    class="list"
    :class="{ light: this.$optixConfiguration.options.colorOnNavBar != 'dark' }"
  >
    <div
      v-for="n in total"
      :key="n"
      :class="{ 'optix-option-accent-bg': n == current }"
    ></div>
  </div>
</template>
<script>
import Steps from "@/mixins/Steps.js";

export default {
  mixins: [Steps],
  name: "optix-dot-progress",
  data() {
    return {
      total: 0,
      current: 1,
    };
  },
  computed: {
    requiresPaymentMethod() {
      return (
        (this.$optixConfiguration.venue.membership.require_payment_method ==
          1 ||
          this.$optixConfiguration.venue.membership
            .require_payment_method_on_charge == 1) &&
        this.$optixConfiguration.venue.payment_gateway &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods.length >
          0
      );
    },
  },
  methods: {},
  mounted() {
    let currentStep = this.$route.name;
    let planSteps = 2;

    if (this.shouldAutoSelectPlan) {
      planSteps = 1;
    }

    let paymentConfirmationSteps = 1;
    if (!this.requiresPaymentMethod && planSteps == 0) {
      paymentConfirmationSteps = 0; // Skip 1 step
    }

    this.total = 4 + planSteps + paymentConfirmationSteps;
    let stepMapping = {
      // Steps missing to the end
      Lookup: paymentConfirmationSteps + planSteps + 3,
      Password: paymentConfirmationSteps + planSteps + 2,
      RecoverPassword: paymentConfirmationSteps + planSteps + 2,
      BasicInfo: paymentConfirmationSteps + planSteps + 2,
      Profile: paymentConfirmationSteps + planSteps + 1,
      Details: paymentConfirmationSteps + planSteps,
      PlanList: paymentConfirmationSteps + 1,
      PlanOptions: paymentConfirmationSteps,
      PayAndConfirm: 0,
      PayAndConfirmPlansV2: 0,
    };
    if (stepMapping.hasOwnProperty(currentStep)) {
      this.current = this.total - stepMapping[currentStep];
    } else {
      this.total = 0;
    }
  },
};
</script>
<style lang="stylus" scoped>
.list {
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  div {
    display: flex;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.24);
    width: 6px;
    height: 6px;
    margin: 3px;
    justify-content: center;
  }

  &.light {
    div {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
