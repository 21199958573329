var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('optix-header',[_vm._v("\n    Debug Control Panel\n  ")]),_c('optix-container',[_c('optix-content-layout',[_c('v-flex',[_c('h3',[_vm._v("Current options")]),_c('pre',[_vm._v(_vm._s(_vm.$optixConfiguration.options))])]),_c('v-flex',[_c('h3',[_vm._v("Current Query Args")]),_c('pre',[_vm._v(_vm._s(_vm.$optixConfiguration.queryStringVars))])]),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"backgroundColor"},model:{value:(_vm.$optixConfiguration.options.backgroundColor),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "backgroundColor", $$v)},expression:"$optixConfiguration.options.backgroundColor"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-select',{attrs:{"items":[
            { value: true, text: 'Enabled' },
            { value: false, text: 'Disabled' },
          ],"label":"darkMode"},model:{value:(_vm.$optixConfiguration.options.darkMode),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "darkMode", $$v)},expression:"$optixConfiguration.options.darkMode"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"accentColor"},model:{value:(_vm.$optixConfiguration.options.accentColor),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "accentColor", $$v)},expression:"$optixConfiguration.options.accentColor"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-select',{attrs:{"items":[
            { value: 'dark', text: 'Dark' },
            { value: 'light', text: 'Light' },
          ],"label":"colorOnAccent"},model:{value:(_vm.$optixConfiguration.options.colorOnAccent),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "colorOnAccent", $$v)},expression:"$optixConfiguration.options.colorOnAccent"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"navBarColor"},model:{value:(_vm.$optixConfiguration.options.navBarColor),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "navBarColor", $$v)},expression:"$optixConfiguration.options.navBarColor"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-select',{attrs:{"items":[
            { value: 'dark', text: 'Dark' },
            { value: 'light', text: 'Light' },
          ],"label":"colorOnNavBar"},model:{value:(_vm.$optixConfiguration.options.colorOnNavBar),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "colorOnNavBar", $$v)},expression:"$optixConfiguration.options.colorOnNavBar"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"fontFamily"},model:{value:(_vm.$optixConfiguration.options.fontFamily),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "fontFamily", $$v)},expression:"$optixConfiguration.options.fontFamily"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-select',{attrs:{"items":[
            { value: 'regular', text: 'Regular' },
            { value: 'solo', text: 'Solo' },
            { value: 'box', text: 'Box' },
            { value: 'outline', text: 'Outline' },
          ],"label":"formInputStyle"},model:{value:(_vm.$optixConfiguration.options.formInputStyle),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "formInputStyle", $$v)},expression:"$optixConfiguration.options.formInputStyle"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"navBarShadow"},model:{value:(_vm.$optixConfiguration.options.navBarShadow),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "navBarShadow", $$v)},expression:"$optixConfiguration.options.navBarShadow"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-text-field',{attrs:{"label":"buttonCornerRadius"},model:{value:(_vm.$optixConfiguration.options.buttonCornerRadius),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "buttonCornerRadius", $$v)},expression:"$optixConfiguration.options.buttonCornerRadius"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('optix-select',{attrs:{"items":[
            { value: true, text: 'Yes' },
            { value: false, text: 'No' },
          ],"label":"raisedButtons"},model:{value:(_vm.$optixConfiguration.options.raisedButtons),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.options, "raisedButtons", $$v)},expression:"$optixConfiguration.options.raisedButtons"}})],1),_c('v-flex',[_c('h3',[_vm._v("User data")]),_c('pre',{staticStyle:{"overflow-x":"auto","width":"100%"}},[_vm._v(_vm._s(_vm.$optixConfiguration.user))])]),_c('v-flex',[_c('h3',[_vm._v("Venue data")]),_c('pre',{staticStyle:{"overflow-x":"auto","overflow-y":"auto","width":"100%","max-height":"300px"}},[_vm._v(_vm._s(_vm.$optixConfiguration.venue))])])],1)],1),_c('optix-footer-navigation',[_c('optix-dot-progress',{attrs:{"slot":"center"},slot:"center"}),_c('optix-btn',{attrs:{"slot":"right"},on:{"click":function($event){return _vm.$router.back()}},slot:"right"},[_vm._v("Back")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }