<template>
  <v-layout
    align-center
    class="layoutpadding"
    :class="{ noHorizontalBorders: noHBorders }"
  >
    <v-flex xs12 align-center justify-center class="content">
      <slot></slot>
    </v-flex>
  </v-layout>
</template>
<script>
//sm10 offset-sm1 md8 offset-md2 lg6
export default {
  name: "optix-content-layout",
  props: {
    noHorizontalBorders: { default: false },
  },
  computed: {
    noHBorders() {
      return (
        this.noHorizontalBorders &&
        (this.$optixConfiguration.queryStringVars.closeable ||
          this.$optixConfiguration.queryStringVars.embedded ||
          this.forceCloseable)
      );
    },
  },
  methods: {},
};
</script>
<style lang="stylus" scoped>
.content {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
