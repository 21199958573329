<template>
  <div>
    <v-layout row align-center>
      <v-flex class="oneline">
        <optix-text-field
          label="Card number"
          type="number"
          pattern="\d*"
          class="mr-1"
          :error-messages="errors.cardNumber"
          v-model="cardNumber"
          @keyup="cleanCardNumber"
        />
      </v-flex>
      <v-flex class="date">
        <optix-text-field
          ref="expireMonth"
          type="number"
          pattern="\d*"
          label="MM"
          v-model="expireMonth"
          @keyup="cleanExpireMonth"
          :min="1"
          :max="12"
          class="mr-1"
          :error="errors.expireMonth && errors.expireMonth != ''"
        />
      </v-flex>
      <v-flex class="date">
        <optix-text-field
          ref="expireYear"
          type="number"
          pattern="\d*"
          label="YY"
          v-model="expireYear"
          @keyup="cleanExpireYear"
          :min="1"
          :max="99"
          :error="errors.expireYear && errors.expireYear != ''"
        />
      </v-flex>
    </v-layout>
    <v-layout row align-center>
      <v-flex class="cvc">
        <optix-text-field
          type="number"
          ref="cvc"
          pattern="\d*"
          label="CVC"
          v-model="cvc"
          class="pl-0"
          @keyup="cleanCvc"
          :min="100"
          :max="9999"
          :error="errors.cvc && errors.cvc != ''"
        />
      </v-flex>
      <v-flex class="zip">
        <optix-text-field
          ref="zip"
          label="ZIP/Postal code"
          class="pl-1"
          v-model="zip"
          :error="errors.zip && errors.zip != ''"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import axios from "axios";
import forEach from "lodash/forEach";

export default {
  mixins: [Validate],
  name: "optix-credit-card",
  props: {
    processor: { default: null },
  },
  data() {
    return {
      cardNumber: "",
      expireMonth: "",
      expireYear: "",
      cvc: "",
      zip: "",
    };
  },
  watch: {},
  computed: {
    requiresZip() {
      return this.processor == 2;
    },
  },
  methods: {
    cleanCardNumber(event) {
      let clean = this.cardNumber.toString().replace(/[^0-9]+/, "");
      if (clean != this.cardNumber) {
        this.cardNumber = clean;
      }
      if (event.key != "Tab" && event.key != "Shift" && clean.length == 19) {
        this.$refs.expireMonth.focus();
      }
    },
    cleanExpireMonth(event) {
      let clean = this.expireMonth.toString().replace(/[^0-9]+/, "");
      if (clean != this.expireMonth) {
        this.expireMonth = clean;
      }
      if (this.expireMonth > 12) {
        this.expireMonth = 12;
      }
      if (this.expireMonth < 0 && this.expireMonth != "") {
        this.expireMonth = 1;
      }
      if (
        event.key != "Tab" &&
        event.key != "Shift" &&
        (this.expireMonth > 1 || this.expireMonth.length == 2)
      ) {
        this.$refs.expireYear.focus();
      }
    },
    cleanExpireYear(event) {
      let clean = this.expireYear.toString().replace(/[^0-9]+/, "");
      if (clean != this.expireYear) {
        this.expireYear = clean;
      }
      if (this.expireYear > 50) {
        this.expireYear = 50;
      }
      if (this.expireYear < 1 && this.expireYear != "") {
        this.expireYear = 1;
      }
      if (
        event.key != "Tab" &&
        event.key != "Shift" &&
        this.expireYear.length == 2
      ) {
        this.$refs.cvc.focus();
      }
    },
    cleanCvc(event) {
      let clean = this.cvc
        .toString()
        .replace(/[^0-9]+/, "")
        .substring(0, 4);
      if (clean != this.cvc) {
        this.cvc = clean;
      }
      if (event.key != "Tab" && event.key != "Shift" && this.cvc.length == 4) {
        if (this.$refs.zip) {
          this.$refs.zip.focus();
        }
      }
    },
    validateInfo() {
      let currentDate = new Date();
      let currentYear = currentDate
        .getFullYear()
        .toString()
        .substring(2, 4);
      let checkMonth = (month) => {
        if (
          month == "" ||
          (this.expireYear == currentYear && currentDate.getMonth() > month) ||
          month < 1 ||
          month > 12
        ) {
          return "Invalid month";
        }
        return "";
      };
      let checkYear = (year) => {
        if (year < currentYear || year > 99 || year == "") {
          return "Invalid year";
        }
        return "";
      };
      let zipValidation = [];
      if (this.requiresZip) {
        zipValidation.push(Validate.rules.required);
      }
      return this.validateForm(this, {
        cardNumber: [
          Validate.rules.required,
          Validate.rules.min(14),
          Validate.rules.max(19),
        ],
        expireMonth: [checkMonth],
        expireYear: [checkYear],
        cvc: [(value) => (value > 0 && value < 9999 ? "" : "Invalid field")],
        zip: zipValidation,
      });
    },
    collectSpreedlyErrors(errors) {
      let generic = [];
      forEach(errors, (error) => {
        switch (error.attribute) {
          case "number":
            this.addError("cardNumber", error.message);
            break;
          case "year":
            this.addError("expireYear", error.message);
            break;
          case "month":
            this.addError("expireMonth", error.message);
            break;
          case "verification_value":
          case "cvc":
            this.addError("cvc", error.message);
            break;
          case "zip":
            this.addError("zip", error.message);
            break;
          default:
            generic.push(error.message);
        }
      });
      return generic;
    },
    collectStripeErrors(error) {
      let generic = [];
      switch (error.param) {
        case "number":
          this.addError("cardNumber", error.message);
          break;
        case "year":
          this.addError("expireYear", error.message);
          break;
        case "month":
          this.addError("expireMonth", error.message);
          break;
        case "verification_value":
        case "cvc":
          this.addError("cvc", error.message);
          break;
        case "zip":
          this.addError("zip", error.message);
          break;
        default:
          generic.push(error.message);
      }
      return generic;
    },
    createToken() {
      return new Promise((accept, reject) => {
        if (this.processor == "1") {
          // Stripe
          let stripe_pk = this.$optixEnv.getVar("stripe_public_key");
          // Use publishable key of the connected account if possible
          if (
            this.$optixConfiguration.venue.payment_gateway.publishable_api_key
          ) {
            stripe_pk = this.$optixConfiguration.venue.payment_gateway
              .publishable_api_key;
          }
          Stripe.setPublishableKey(stripe_pk);
          Stripe.card.createToken(
            {
              name:
                this.$optixConfiguration.user.data.name +
                " " +
                this.$optixConfiguration.user.data.surname,
              address_zip: this.zip,
              number: this.cardNumber,
              cvc: this.cvc,
              exp_month: this.expireMonth,
              exp_year:
                this.expireYear < 2000
                  ? parseFloat(this.expireYear) + 2000
                  : this.expireYear,
            },
            (status, res) => {
              if (res.error) {
                let messages = this.collectStripeErrors(res.error);
                reject({
                  errors: [res.error],
                  messages,
                });
              } else {
                let acceptObject = {
                  details: [],
                  venue_id: this.$optixConfiguration.venue.venue_id,
                  member_id: this.$optixConfiguration.user.lookup
                    .member_account,
                  // org_id: window.optix.org_id,
                  processor_id: this.processor,
                  token_id: res.id,
                  token: res,
                  type_id: 1,
                };
                this.$emit("input", acceptObject);
                accept(acceptObject);
              }
            }
          );
        } else if (this.processor == "2") {
          // Spreedly
          let data = {
            payment_method: {
              credit_card: {
                first_name: this.$optixConfiguration.user.data.name,
                last_name: this.$optixConfiguration.user.data.surname,
                email: this.$optixConfiguration.user.data.email,
                kind: "credit_card",
                number: this.cardNumber,
                verification_value: this.cvc,
                month: this.expireMonth,
                year: parseFloat(this.expireYear) + 2000,
                zip: this.zip,
              },
            },
          };
          let spreedlyKey = this.$optixEnv.getVar("spreedly_key");
          let url = `https://core.spreedly.com/v1/payment_methods.json?environment_key=${spreedlyKey}`;

          axios
            .post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status == 201) {
                let acceptObject = {
                  venue_id: this.$optixConfiguration.venue.venue_id,
                  member_id: this.$optixConfiguration.user.lookup
                    .member_account,
                  //org_id: window.optix.org_id,
                  processor_id: this.processor,
                  details: response.data.transaction.payment_method,
                  retained: true,
                  type_id: 1,
                };
                this.$emit("input", acceptObject);
                accept(acceptObject);
              } else {
                let messages = this.collectSpreedlyErrors(response.data.errors);
                reject({
                  errors: response.data.errors,
                  messages,
                });
              }
            })
            .catch((error) => {
              let messages = this.collectSpreedlyErrors(
                error.response.data.errors
              );
              reject({
                errors: error.response.data.errors,
                messages,
              });
            });
        } else {
          // ???
          console.log("Wrong card processor");
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.slash {
  flex-basis: 10px;
  max-width: 10px;
}

.oneline {
  // flex-basis: 100%;
}

.cvc {
  flex-basis: 110px;
  max-width: 110px;
}

.date {
  flex-basis: 80px;
  max-width: 80px;
}
</style>
