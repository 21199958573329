<template>
  <v-dialog
    v-if="isMobile"
    ref="dialog"
    v-model="modalOpen"
    persistent
    lazy
    full-width
    width="290px"
  >
    <optix-text-field
      slot="activator"
      v-model="formattedDate"
      :label="label"
      append-icon="event"
      :persistent-hint="persistentHint"
      :hint="hint"
      :error-messages="errorMessages"
      readonly
    />
    <v-date-picker
      v-model="date"
      scrollable
      color="optix-option-accent-bg"
      :min="min"
    >
      <v-spacer></v-spacer>
      <v-btn flat color="optix-option-accent" @click="modalOpen = false"
        >Close</v-btn
      >
    </v-date-picker>
  </v-dialog>
  <v-menu
    v-else
    ref="menu"
    v-model="menuOpen"
    lazy
    transition="scale-transition"
    :close-on-content-click="false"
    offset-y
    full-width
    min-width="290px"
  >
    <optix-text-field
      slot="activator"
      v-model="formattedDate"
      :label="label"
      append-icon="event"
      :persistent-hint="persistentHint"
      :hint="hint"
      :error-messages="errorMessages"
      readonly
    />
    <v-date-picker v-model="date" color="optix-option-accent-bg" :min="min">
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "optix-date",
  props: {
    value: { default: null },
    label: { default: null },
    min: { default: null },
    "error-messages": { default: null },
    hint: { default: null },
    "persistent-hint": { default: null },
  },
  data() {
    return {
      date: null,
      modalOpen: false,
      menuOpen: false,
    };
  },
  mounted() {
    this.date = this.value;
  },
  watch: {
    date(newValue) {
      this.$emit("input", newValue);
      this.menuOpen = false;
    },
    value(newValue) {
      this.date = newValue;
    },
  },
  computed: {
    isMobile() {
      return window.navigator.userAgent.match(
        /Android|webOS|iPhone|iPod|Blackberry|iPad/i
      );
    },
    formattedDate() {
      if (this.date) {
        let parts = this.date.split("-");
        return new Date(parts[0], parts[1] - 1, parts[2]).toLocaleDateString();
      }
      return "";
    },
  },
};
</script>
