<template>
  <div>
    <optix-header>
      Get started
    </optix-header>
    <optix-container>
      <optix-content-layout>
        <p v-if="!isInquiryMode" class="mb-5 mt-2">
          {{
            $optixConfiguration.options.onboarding.welcomeMessage.length
              ? $optixConfiguration.options.onboarding.welcomeMessage
              : "We're excited to have you join our community. Please complete the following steps to get started."
          }}
        </p>
        <p v-else class="mb-5 mt-2">
          {{
            $optixConfiguration.options.inquiry &&
            $optixConfiguration.options.inquiry.welcomeMessage
              ? $optixConfiguration.options.inquiry.welcomeMessage
              : "We're excited to have you join our community. Please complete the following steps to get started."
          }}
        </p>

        <optix-form ref="form">
          <optix-text-field
            label="Email address"
            type="email"
            ref="email"
            name="email"
            id="email"
            persistent-hint
            v-model="user.email"
            :error-messages="errors.email"
            @keydown.enter.prevent="next"
            :hint="
              isInquiryMode
                ? ''
                : 'This will be the email address you will use to log in to our mobile app'
            "
          />

          <recaptcha
            ref="recaptcha"
            v-model="user.recaptcha_token"
            @verify="next"
          />

          <v-layout v-if="!isInquiryMode" row justify-center>
            <div class="my-4 mb-5 justify-center">OR</div>
          </v-layout>

          <v-layout v-if="!isInquiryMode" row justify-center>
            <div class="pr-4">
              <img
                @click="socialLogin('facebook')"
                src="@/assets/asset-login-facebook.png"
                srcset="
                  @/assets/asset-login-facebook@2x.png 2x,
                  @/assets/asset-login-facebook@3x.png 3x
                "
                class="clickable"
              />
            </div>
            <div>
              <img
                @click="socialLogin('google')"
                src="@/assets/asset-login-google.png"
                srcset="
                  @/assets/asset-login-google@2x.png 2x,
                  @/assets/asset-login-google@3x.png 3x
                "
                class="clickable"
              />
            </div>
            <div class="pl-4">
              <img
                @click="socialLogin('linkedin')"
                src="@/assets/asset-login-linkedin.png"
                srcset="
                  @/assets/asset-login-linkedin@2x.png 2x,
                  @/assets/asset-login-linkedin@3x.png 3x
                "
                class="clickable"
              />
            </div>
          </v-layout>

          <v-layout row justify-center class="terms pt-5">
            <div>
              By continuing, you accept the
              <a href="https://login.optixapp.com/terms" target="_blank"
                >Terms &amp; Conditions</a
              >
              and
              <a href="https://login.optixapp.com/privacy" target="_blank"
                >Privacy Policy</a
              ><template v-if="hasVenueTerms"
                >, as well as
                <a @click="showVenueTerms = true"
                  >{{ $optixConfiguration.venue.name }} Terms</a
                ></template
              >.
            </div>
            <v-dialog v-model="showVenueTerms" width="500">
              <v-card>
                <v-card-title primary-title class="headline">
                  {{ $optixConfiguration.venue.name }}'s terms
                </v-card-title>

                <v-card-text class="term-text">
                  <div
                    v-html="$optixConfiguration.venue.custom_terms_original"
                  ></div>
                  <div
                    v-html="$optixConfiguration.venue.custom_terms_appendix"
                  ></div>
                </v-card-text>
                <!-- safer but bad style
                <iframe :src="terms" border="0" style="border:0px; width:100%;height:450px"/>
                -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <optix-btn flat @click="showVenueTerms = false">
                    Close
                  </optix-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-dot-progress slot="center" />
      <optix-btn slot="right" @click="next" :loading="nextLoading"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Recaptcha from "@/components/Recaptcha";
import Validate from "@/mixins/Validate.js";
import Steps from "@/mixins/Steps";

export default {
  components: { Recaptcha },
  mixins: [Validate, Steps],
  data() {
    return {
      nextLoading: false,
      showVenueTerms: false,
      user: {
        email: "",
        recaptcha_token: "",
      },
    };
  },
  computed: {
    hasVenueTerms() {
      return (
        this.$optixConfiguration.venue.custom_terms_original ||
        this.$optixConfiguration.venue.custom_terms_appendix
      );
    },
    terms() {
      return (
        "data:text/html;charset=utf-8," +
        encodeURIComponent(this.$optixConfiguration.venue.custom_terms)
      );
    },
  },
  mounted() {
    this.$optixConfiguration.resetUser();
    if (!this.$optixConfiguration.queryStringVars.embedded) {
      this.$nextTick(() => {
        this.$refs.email.focus();
      });
    }
  },
  watch: {},
  methods: {
    socialLogin: function(provider) {
      let modal = window.open(
        this.$optixEnv.getVar("api") +
          "/auth/web/social?state=" +
          this.$optixConfiguration.venue.venue_id +
          ":" +
          provider,
        "socialPopupWindow",
        "location=no,width=600,height=600,scrollbars=yes,top=100,left=200"
      );
    },
    next() {
      this.validateForm(this.user, {
        email: [Validate.rules.email],
      })
        .then(() => {
          if (!this.user.recaptcha_token) {
            this.$refs.recaptcha.execute();
            return;
          }

          this.nextLoading = true;
          this.$optixWS
            .call(
              "POST",
              "/members/mobile/lookup/",
              {},
              {
                venue_id: this.$optixConfiguration.venue.venue_id,
                email: this.user.email,
                member_type: this.isInquiryMode ? "inquiry" : "onboarding",
                recaptcha_token: this.user.recaptcha_token,
              }
            )
            .then((success) => {
              this.$optixConfiguration.user.lookup = success.data.response;
              this.$optixConfiguration.user.data.email = this.user.email;
              this.nextLoading = false;
              if (
                this.isInquiryMode &&
                success.data.response.member_status == "5"
              ) {
                this.$router.push({ name: "BasicInfo" });
              } else if (
                this.isInquiryMode &&
                success.data.response.member_status != "5"
              ) {
                this.$router.push({ name: "AlreadyMember" });
              } else if (success.data.response.user_status == "1") {
                // Active user, ready to login
                this.$router.push({ name: "Password" });
              } else if (success.data.response.user_status == "2") {
                // New user, must set a password
                this.$router.push({ name: "BasicInfo" });
              } else {
                // Inactive or disabled user
                this.addError("email", "Oops, you cannot login");
              }
            })
            .catch((error) => {
              this.$optixConfiguration.showSystemError("Lookup error", error);
              this.nextLoading = false;
              this.$refs.form.focusErrors();
              this.$refs.recaptcha.reset();
            });
        })
        .catch((e) => {
          this.$refs.form.focusErrors();
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.clickable {
  cursor: pointer;
}

.terms {
  .v-input {
    margin-top: 0;
  }
}

.term-text {
  white-space: pre-line;
}
</style>
