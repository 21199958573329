var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"component",staticClass:"uploader-singlefile mb-4 v-text-field",class:{
    darkMode: _vm.$optixConfiguration.options.darkMode,
    'v-input--has-state': _vm.errorMessages && _vm.errorMessages.length > 0,
    'error--text': _vm.errorMessages && _vm.errorMessages.length > 0,
  }},[_c('h4',{ref:"title",staticClass:"v-label mb-2 title",class:{ 'error--text': _vm.errorMessages && _vm.errorMessages.length > 0 }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_c('file-upload',{ref:"upload",attrs:{"extensions":_vm.extensions,"accept":_vm.accept,"drop":true,"name":"datacontent"},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[(
        (!_vm.files.length || _vm.files[0].error) &&
          (_vm.$refs.upload && !_vm.$refs.upload.dropActive) &&
          !(_vm.value && !_vm.value.match(/sd_avatar_blue/))
      )?_c('div',{staticClass:"instructions"},[_c('div',{ref:"ints"},[_c('h4',[_vm._v("Drag and drop image here")]),_c('p',[_vm._v("Or")]),_c('h4',[_c('optix-btn',{attrs:{"href":"javascript:;","flat":""},on:{"click":_vm.selectImage}},[_vm._v("Upload a photo")])],1),(_vm.files.length && _vm.files[0].error)?_c('div',{staticClass:"error-message"},[(_vm.files[0].error == 'size')?_c('span',[_vm._v("Maximum file size exceeded.")]):_vm._e(),(_vm.files[0].error == 'extension')?_c('span',[_vm._v("Extension not allowed.")]):_vm._e()]):_vm._e()])]):_vm._e(),(
        (_vm.files.length &&
          !_vm.files[0].error &&
          (_vm.$refs.upload && !_vm.$refs.upload.dropActive)) ||
          (_vm.value && !_vm.value.match(/sd_avatar_blue/))
      )?_c('div',{staticClass:"instructions"},[_c('div',{ref:"ints"},[_c('div',{staticClass:"filename"},[(_vm.thumbnail)?_c('img',{attrs:{"src":_vm.thumbnail}}):_vm._e()]),_c('h4',[_c('optix-btn',{attrs:{"href":"javascript:;","flat":""},on:{"click":_vm.selectImage}},[_vm._v("Change")])],1)])]):_vm._e(),(_vm.$refs.upload && _vm.$refs.upload.dropActive)?_c('div',{staticClass:"dropzone"},[_c('div',[_vm._v("\n        Drop your file here!\n      ")])]):_vm._e()]),(_vm.errorMessages && _vm.errorMessages.length > 0)?_c('div',{staticClass:"v-messages error--text"},[_vm._v("\n    "+_vm._s(_vm.errorMessages[0])+"\n  ")]):(_vm.hint)?_c('div',{staticClass:"v-messages"},[_vm._v("\n    "+_vm._s(_vm.hint)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }