/**
 * Handle all optix configuration
 */
import forEach from "lodash/forEach";
import OptixWS from "./OptixWS";
import OptixEnv from "./OptixEnv";

export default {
  Vue: false,
  args: {},
  install: function install(Vue, args) {
    this.Vue = Vue;
    this.args = args;

    Vue.prototype.$optixWS = OptixWS;
    Vue.prototype.$optixEnv = OptixEnv;
    Vue.prototype.$optixWS.api_url = Vue.prototype.$optixEnv.getVar("api");
    Vue.prototype.$optixWS.api2_url = Vue.prototype.$optixEnv.getVar("api2");

    Vue.prototype.$optixConfiguration = new Vue({
      data: {
        snackbar: {
          open: false,
          type: "success",
          timeout: false,
          messages: [],
        },
        // Theme options
        options: {
          backgroundColor: "#ffffff",
          darkMode: false,
          accentColor: "#007AFF",
          colorOnAccent: "light",
          navBarColor: "#ffffff",
          colorOnNavBar: "dark",
          navBarShadow: 4,
          fontFamily: "Roboto",
          formInputStyle: "regular",
          buttonCornerRadius: "18px",
          raisedButtons: true,
          images: [],
          onboarding: {
            welcomeMessage: "",
            thankyouMessage: "",
          },
        },
        title: "Sign up",
        stylesheet: false, // Dynamic Stylesheet, do not change this var, check recreateStylesheet method
        queryStringVars: {}, // Hold all iframe GET vars
        venue: {}, // Hold all venue data
        user: {
          lookup: {},
          data: {},
          access_token: false,
        },
        signupData: {
          // Holds information about what the user is subscribing too
          selectedPlanId: null,
          selectedPlan: null,
          planStartDate: null,
          planDuration: null,
          planEndDate: null,
          selectedItem: null,
          selectedItemCode: null,
        },
        member: {
          data: {},
        },
        autoPassword: false, // Tries to log the user in using this password
      },
      watch: {
        options: {
          handler: function() {
            return this.recreateStylesheet();
          },
          deep: true,
        },
        title(newValue) {
          window.document.title = newValue;
        },
        user: {
          handler: function(newValue) {
            window.localStorage.setItem(
              "optixMWUser",
              JSON.stringify(newValue)
            );
          },
          deep: true,
        },
        venue: {
          handler: function(newValue, oldValue) {
            if (this.title == "Sign up") {
              this.title = newValue.name + " - Sign up";
            }
            if (newValue.logo != oldValue.logo) {
              this.changeFavicon(newValue.logo);
            }
          },
          deep: true,
        },
        signupData: {
          handler: function(newValue, oldValue) {
            for (var i in this.venue.plans) {
              var plan = this.venue.plans[i];
              if (plan.plan_id == this.signupData.selectedPlanId) {
                this.signupData.selectedPlan = plan;
              }
            }
            if (newValue.planStartDate && newValue.planDuration > 0) {
              let date = new Date(newValue.planStartDate);
              date.setMonth(date.getMonth() + newValue.planDuration);
              date.setDate(date.getDate() - 1); // the end date is inclusive
              this.signupData.planEndDate = date.toISOString().substr(0, 10);
            }
          },
          deep: true,
        },
      },
      created() {
        let user_info = window.localStorage.getItem("optixMWUser");
        if (user_info) {
          this.user = JSON.parse(user_info);
          if (this.user.access_token) {
            this.$optixWS.access_token = this.user.access_token;
          }
        }

        // Create dynamic stylesheet tag
        this.stylesheet = document.createElement("style");
        this.stylesheet.type = "text/css";
        this.stylesheet.id = "optix-rendered-stylesheet";
        this.recreateStylesheet();
        document.getElementsByTagName("html")[0].appendChild(this.stylesheet);

        // Listen for incoming javascript style messages
        window.addEventListener("message", (message) => {
          try {
            switch (message.data.command) {
              case "changeOptions":
                Object.assign(this.options, message.data.options);
                break;
            }
          } catch (e) {}
        });

        window.document.title = this.title;
      },
      methods: {
        // Load venue options from API
        loadOptions(queryStringVars) {
          this.queryStringVars = queryStringVars;
          if (
            this.queryStringVars.style != undefined &&
            this.queryStringVars.style !== null &&
            typeof this.queryStringVars.style === "object"
          ) {
            forEach(this.queryStringVars.style, (value, prop) => {
              this.options[prop] = value;
            });
          }
          return new Promise((accept, reject) => {
            let subdomain = false;

            if (!this.queryStringVars.venue) {
              this.queryStringVars.venue = window.location.host.replace(
                /\:.+/,
                ""
              );
            }

            if (
              this.queryStringVars.venue &&
              this.queryStringVars.venue.match(/\.staging\./)
            ) {
              this.$optixEnv.forceEnvironment = "staging";
              this.$optixWS.api_url = this.$optixEnv.getVar("api");
              this.$optixWS.api2_url = this.$optixEnv.getVar("api2");
              subdomain = this.queryStringVars.venue.replace(/\..+$/, "");
            } else if (this.queryStringVars.venue == "localhost") {
              subdomain = "qa";
            } else {
              subdomain = this.queryStringVars.venue.replace(/\..+$/, "");
            }

            let mode = "Standalone";
            if (this.queryStringVars.closeable) {
              mode = "Modal";
            } else if (this.queryStringVars.embedded) {
              mode = "Embedded";
            }

            this.$ga.set({
              dimension1: subdomain, // Venue
              dimension2: mode, // Mode
            });
            OptixWS.call("GET", "/venues/", {
              params: { subdomain: subdomain },
            })
              .then((success) => {
                if (
                  success.data.response &&
                  success.data.response.length == 1 &&
                  success.data.response[0].venue_id > 0
                ) {
                  // Get full context for this venue
                  OptixWS.call(
                    "GET",
                    "/venues/get/" + success.data.response[0].venue_id,
                    {
                      params: {
                        scope:
                          "full,web_plugin,membership,plans,payment_gateway",
                        portal: 1,
                      },
                    }
                  )
                    .then((success) => {
                      if (
                        success.data.response &&
                        success.data.response.venue_id > 0
                      ) {
                        this.venue = success.data.response;
                        let allow_subscription = false;
                        // Use the new flag, if defined
                        if (
                          this.venue.membership.enable_web_onboarding !==
                            undefined &&
                          this.venue.membership.enable_web_onboarding !==
                            null &&
                          this.venue.membership.enable_web_onboarding !== ""
                        ) {
                          if (
                            this.venue.membership.enable_web_onboarding ==
                              "1" ||
                            this.venue.membership.enable_inquiry == "1"
                          ) {
                            allow_subscription = true;
                          }
                        } else if (
                          this.venue.membership.enable_member_subscribe
                        ) {
                          // Otherwise check if old flag is enabled
                          allow_subscription = true;
                        }
                        // Block inactive venues
                        if (
                          this.venue.membership.status != "1" ||
                          this.venue.status != "1"
                        ) {
                          allow_subscription = false;
                        }
                        if (!allow_subscription) {
                          reject(
                            "Member onboarding is not enabled at this venue"
                          );
                        } else {
                          Object.assign(this.options, this.venue.web_plugin);
                          accept(this.venue);
                        }
                      } else {
                        reject(
                          "Error retrieving venue information. Please try again later."
                        );
                      }
                    })
                    .catch((error) => {
                      reject(
                        "Error retrieving venue information. Please try again later."
                      );
                    });
                } else {
                  reject("Venue not found");
                }
              })
              .catch((error) => {
                reject(
                  "Error retrieving venue information. Please try again later."
                );
              });
          });
        },
        // Recreate base theme stylesheet
        recreateStylesheet() {
          let navFontColor =
            this.options.colorOnNavBar == "dark" ? "#000" : "#fff";
          let defaultFontColor = !this.options.darkMode ? "#000" : "#fff";
          this.stylesheet.innerHTML = `
                        @import url('https://fonts.googleapis.com/css?family=${
                          this.options.fontFamily
                        }');
                        .theme--dark.application, .theme--light.application, .application--wrap { background: transparent; }
                        html, body { background: ${
                          this.options.backgroundColor
                        };
                        --optix-theme-defaultFontColor: ${defaultFontColor}}
                        html .application { font-family: '${
                          this.options.fontFamily
                        }' !important }
                        .optix-option-background { background: ${
                          this.options.backgroundColor
                        } !important }
                        .optix-option-accent--text { color: ${
                          this.options.accentColor
                        } !important }
                        html .application a { color: ${
                          this.options.accentColor
                        } !important }
                        html .application a .v-list__tile__title { color: ${defaultFontColor} !important }
                        html .application a.v-list__tile--active .v-list__tile__title { color: ${
                          this.options.accentColor
                        } !important }
                        .optix-option-accent-bg { background: ${
                          this.options.accentColor
                        } !important }
                        .optix-option-accent-button--text { color: ${
                          this.options.accentColor
                        } !important; border-radius: ${
            this.options.buttonCornerRadius
          } !important  }
                        .optix-option-accent-button-bg { background: ${
                          this.options.accentColor
                        } !important; border-radius: ${
            this.options.buttonCornerRadius
          } !important  }
                        .optix-option-navBar { background: ${
                          this.options.navBarColor
                        } !important; color: ${navFontColor} }
                        .v-toolbar__content * {color: ${navFontColor} }
                        `;
        },
        // Change page favicon
        changeFavicon(src) {
          document.head ||
            (document.head = document.getElementsByTagName("head")[0]);
          let link = document.createElement("link"),
            oldLink = document.getElementById("dynamic-favicon");
          link.id = "dynamic-favicon";
          link.rel = "icon";
          link.type = "image/png";
          link.href = src;
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
        },
        // Reset the entire user
        resetUser() {
          this.user = {
            lookup: {},
            data: {},
            access_token: false,
          };
        },
        // Show a snackbar message
        showMessage(message, type, timeout) {
          if (!timeout) {
            timeout = 6000;
          }
          if (!type) {
            type = "info";
          }
          if (this.snackbar.timeout) {
            clearTimeout(this.snackbar.timeout);
            this.snackbar.messages.push(message);
          } else {
            this.snackbar.messages = [message];
          }
          this.snackbar.open = true;
          this.snackbar.type = type;
          this.snackbar.timeout = setTimeout(() => {
            this.snackbar.open = false;
            this.snackbar.timeout = false;
          }, timeout);
        },
        showSystemError(log_message, details, public_message) {
          let message = "Sorry, an unexpected error happened";
          // Error message from API
          if (
            details.response &&
            details.response.data &&
            details.response.data.response &&
            details.response.data.response.message
          ) {
            message = details.response.data.response.message;
          } else if (public_message) {
            message = public_message;
          }
          this.showMessage(message, "error");
          console.log(message, details);
          OptixWS.trackError(log_message, details);
          if (this.$ga) {
            this.$ga.exception(message);
          }
        },
      },
    });
  },
  version: "0.1",
};
