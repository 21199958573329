var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"optix-app",attrs:{"dark":_vm.$optixConfiguration.options.darkMode}},[(_vm.loading && !_vm.error)?_c('div',{staticClass:"optix-widget-preloader"},[_c('svg',{staticClass:"spinner",attrs:{"width":"40px","height":"40px","viewBox":"0 0 66 66","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"circle",attrs:{"fill":"none","stroke-width":"4","stroke-linecap":"round","cx":"33","cy":"33","r":"30"}})])]):_vm._e(),(!_vm.loading && !_vm.error)?_c('div',{staticClass:"main-container optix-fadein",class:{ light: _vm.$optixConfiguration.options.darkMode }},[_c('div',{class:{
        'left-container': true,
        'left-container-inquiry': _vm.isInquiry,
        'left-container-inquiry-embedded': _vm.isEmbeddedOrPopUp,
      },on:{"click":function($event){return _vm.debugRequest()}}},[_c('router-view')],1),(!_vm.isInquiry)?_c('div',{staticClass:"right-container"},[(_vm.slideshowImages.length)?_c('div',{staticClass:"slideshow optix-option-background"},_vm._l((_vm.slideshowImages),function(item,i){return _c('div',{key:i,style:({
            'background-image':
              'url(' + (item.url ? item.url : item.data) + ')',
            opacity: i == _vm.slideshowImageCounter ? 1 : 0,
          })})}),0):_vm._e()]):_vm._e()]):_vm._e(),(_vm.error)?_c('div',{staticClass:"main-container optix-fadein",class:{ light: _vm.$optixConfiguration.options.darkMode }},[_c('div',{staticClass:"left-container error-container",on:{"click":function($event){return _vm.debugRequest()}}},[_c('optix-header',{attrs:{"forceCloseable":true}},[_vm._v("\n        Unable to load form\n      ")]),_c('div',{staticClass:"error-block"},[_c('div',{staticClass:"icon"},[(_vm.$optixConfiguration.options.darkMode)?_c('img',{attrs:{"src":require("@/assets/onboarding-error-dark.svg")}}):_c('img',{attrs:{"src":require("@/assets/onboarding-error-light.svg")}})]),_c('h4',{staticClass:"message"},[_vm._v("\n          "+_vm._s(_vm.formErrorMessage)+"\n        ")]),_c('div',{staticClass:"button"},[_c('optix-btn',{on:{"click":function($event){return _vm.loadVenue()}}},[_vm._v("\n            Reload\n          ")])],1)])],1)]):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.$optixConfiguration.snackbar.type,"multi-line":true,"timeout":0,"top":"","auto-height":""},model:{value:(_vm.$optixConfiguration.snackbar.open),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.snackbar, "open", $$v)},expression:"$optixConfiguration.snackbar.open"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$optixConfiguration.snackbar.messages.join('<br/>'))}}),_c('v-btn',{attrs:{"dark":"","flat":""},on:{"click":function($event){return _vm.closeSnackbar()}}},[_vm._v("\n      Close\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }