<template>
  <div class="optix-widget-preloader optix-fadein">
    <svg
      class="spinner"
      width="40px"
      height="40px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="circle"
        fill="none"
        stroke-width="4"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  </div>
</template>
<script>
// The styles are located on index.html page, since this is used as page preloader too
export default {
  name: "optix-loading",
};
</script>
<style lang="stylus">
.optix-widget-preloader {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
    justify-content: center;
}
</style>
