<template>
  <div
    class="uploader-singlefile mb-4 v-text-field"
    :class="{
      darkMode: $optixConfiguration.options.darkMode,
      'v-input--has-state': errorMessages && errorMessages.length > 0,
      'error--text': errorMessages && errorMessages.length > 0,
    }"
    ref="component"
  >
    <h4
      class="v-label mb-2 title"
      ref="title"
      :class="{ 'error--text': errorMessages && errorMessages.length > 0 }"
    >
      {{ label }}
    </h4>
    <file-upload
      :extensions="extensions"
      :accept="accept"
      :drop="true"
      v-model="files"
      name="datacontent"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <div
        class="instructions"
        v-if="
          (!files.length || files[0].error) &&
            ($refs.upload && !$refs.upload.dropActive) &&
            !(value && !value.match(/sd_avatar_blue/))
        "
      >
        <div ref="ints">
          <h4>Drag and drop image here</h4>
          <p>Or</p>
          <h4>
            <optix-btn href="javascript:;" @click="selectImage" flat
              >Upload a photo</optix-btn
            >
          </h4>
          <div class="error-message" v-if="files.length && files[0].error">
            <span v-if="files[0].error == 'size'"
              >Maximum file size exceeded.</span
            >
            <span v-if="files[0].error == 'extension'"
              >Extension not allowed.</span
            >
          </div>
        </div>
      </div>
      <div
        class="instructions"
        v-if="
          (files.length &&
            !files[0].error &&
            ($refs.upload && !$refs.upload.dropActive)) ||
            (value && !value.match(/sd_avatar_blue/))
        "
      >
        <div ref="ints">
          <div class="filename">
            <img :src="thumbnail" v-if="thumbnail" />
          </div>
          <h4>
            <optix-btn href="javascript:;" @click="selectImage" flat
              >Change</optix-btn
            >
          </h4>
        </div>
      </div>
      <div class="dropzone" v-if="$refs.upload && $refs.upload.dropActive">
        <div>
          Drop your file here!
        </div>
      </div>
    </file-upload>
    <div
      class="v-messages error--text"
      v-if="errorMessages && errorMessages.length > 0"
    >
      {{ errorMessages[0] }}
    </div>
    <div class="v-messages" v-else-if="hint">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import FileUpload from "vue-upload-component";
import exif from "exif-js";

export default {
  name: "optix-profile-image-upload",
  components: {
    FileUpload,
  },
  props: {
    accept: {
      type: String,
      default: null,
    },
    extensions: {
      type: String,
      default: null,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Profile photo",
    },
    errorMessages: {
      default: false,
    },
    hint: {
      default: false,
    },
    value: {},
  },
  data() {
    return {
      files: [],
      recentlyUploaded: false,
      extraData: {},
      headers: {},
      thumbnail: false,
    };
  },
  mounted() {
    if (this.value) {
      this.thumbnail = this.value;
    }
  },
  methods: {
    focus() {
      document.documentElement.scrollTop = 0;
    },
    remove() {
      this.$refs.upload.clear();
      this.$emit("input", "");
    },
    alert(message) {
      alert(message);
    },
    selectImage(e) {
      this.$refs.ints.click();
      return true;
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile) {
        let reader = new FileReader();
        reader.onload = () => {
          // Upload image
          let uploadImage = new Image();
          uploadImage.src = reader.result;
          uploadImage.onload = () => {
            let canvas = document.createElement("canvas");
            canvas.width = 1500;
            canvas.height = 1500;
            this.drawImageProp(canvas, uploadImage)
              .then(() => {
                this.$emit("input", canvas.toDataURL("image/png"));
              })
              .catch((e) => {
                console.log(e);
              });
          };

          // thumbnail image
          let image = new Image();
          image.src = reader.result;
          image.onload = () => {
            let canvas = document.createElement("canvas");
            canvas.width = 64;
            canvas.height = 64;
            this.drawImageProp(canvas, image)
              .then(() => {
                this.thumbnail = canvas.toDataURL("image/png");
              })
              .catch((e) => {
                console.log(e);
              });
          };
        };
        reader.readAsDataURL(newFile.file);
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        let ext = newFile.name.replace(/^.*\.([^.]+)$/, "$1").toLowerCase();
        if (this.extensions && !this.extensions.split(",").includes(ext)) {
          this.alert("Valid extension: " + this.extensions);
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    drawImageProp(canvas, img, x, y, w, h, offsetX, offsetY) {
      return new Promise((accept, reject) => {
        let crop = (orientation) => {
          let ctx = canvas.getContext("2d");

          if (arguments.length === 2) {
            x = y = 0;
            w = canvas.width;
            h = canvas.height;
          }

          let width = w;
          let height = h;

          // default offset is center
          offsetX = typeof offsetX === "number" ? offsetX : 0.5;
          offsetY = typeof offsetY === "number" ? offsetY : 0.5;

          // keep bounds [0.0, 1.0]
          if (offsetX < 0) offsetX = 0;
          if (offsetY < 0) offsetY = 0;
          if (offsetX > 1) offsetX = 1;
          if (offsetY > 1) offsetY = 1;

          var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r, // new prop. width
            nh = ih * r, // new prop. height
            cx,
            cy,
            cw,
            ch,
            ar = 1;

          // decide which gap to fill
          if (nw < w) ar = w / nw;
          if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
          nw *= ar;
          nh *= ar;

          // calc source rectangle
          cw = iw / (nw / w);
          ch = ih / (nh / h);

          cx = (iw - cw) * offsetX;
          cy = (ih - ch) * offsetY;

          // make sure source rectangle is valid
          if (cx < 0) cx = 0;
          if (cy < 0) cy = 0;
          if (cw > iw) cw = iw;
          if (ch > ih) ch = ih;

          switch (orientation) {
            case 2:
              // horizontal flip
              ctx.translate(width, 0);
              ctx.scale(-1, 1);
              break;
            case 3:
              // 180° rotate left
              ctx.translate(width, height);
              ctx.rotate(Math.PI);
              break;
            case 4:
              // vertical flip
              ctx.translate(0, height);
              ctx.scale(1, -1);
              break;
            case 5:
              // vertical flip + 90 rotate right
              ctx.rotate(0.5 * Math.PI);
              ctx.scale(1, -1);
              break;
            case 6:
              // 90° rotate right
              ctx.rotate(0.5 * Math.PI);
              ctx.translate(0, -height);
              break;
            case 7:
              // horizontal flip + 90 rotate right
              ctx.rotate(0.5 * Math.PI);
              ctx.translate(width, -height);
              ctx.scale(-1, 1);
              break;
            case 8:
              // 90° rotate left
              ctx.rotate(-0.5 * Math.PI);
              ctx.translate(-width, 0);
              break;
          }

          // fill image in dest. rectangle
          canvas.getContext("2d").drawImage(img, cx, cy, cw, ch, x, y, w, h);

          accept();
        };
        exif.getData(img, function() {
          let orientation = exif.getTag(this, "Orientation");
          crop(orientation);
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
a {
  text-transform: uppercase;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone * {
  display: block;
  flex-direction: column;
}

.darkMode .file-uploads {
  background: rgba(255, 255, 255, 0.1);

  a {
    color: #fff !important;
  }
}

h4.title {
  color: inherit !important;
}

.instructions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &>div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 70%;

    &>* {
      margin: 0px;
      justify-content: center;
    }

    p {
      font-size: 12px;
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.02em !important;
      height: 0px;
    }
  }
}

.error-message {
  color: red;
}

.uploader-singlefile {
}

.file-uploads {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 156px;
  border-radius: 6px;
}

.filename {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0px;
  }

  img {
    max-height: 64px;
    max-width: 64px;
    border-radius: 50%;
  }
}
</style>
