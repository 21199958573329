<template>
  <div>
    <optix-header :showTitle="false">
      <span v-if="!loadingData">Thank you!</span>
    </optix-header>
    <optix-container>
      <optix-content-layout v-if="loadingData">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else class="complete-boxes">
        <div>
          <img
            v-if="$optixConfiguration.options.darkMode"
            src="@/assets/icon-success-dark.svg"
          />
          <img v-else src="@/assets/icon-success-light.svg" />
        </div>
        <div class="thank-you">
          <div>{{ thankyouMessage }}</div>
        </div>
        <template v-if="!isInquiryMode">
          <div v-if="isDesktop">
            <div class="phone">
              <h4 class="mb-3">You're ready to download our app</h4>

              <optix-phone-field
                ref="phone"
                v-model="phone"
                :error-messages="errors.phone"
              >
              </optix-phone-field>
            </div>

            <optix-btn :loading="sending" @click="sendLink()">
              Send me a link
            </optix-btn>
          </div>

          <h4 v-else class="mb-5 center">You're ready to download our app</h4>

          <div>
            <div class="side-by-side">
              <a
                class="app-store"
                :href="
                  $optixConfiguration.venue.membership.app_config
                    .itunes_app_page
                "
                target="_blank"
                v-if="
                  $optixConfiguration.venue.membership.app_config
                    .itunes_app_page &&
                    (isDesktop || isIOS)
                "
              >
                <img
                  src="@/assets/app-store-download.png"
                  srcset="
                    @/assets/app-store-download@2x.png 2x,
                    @/assets/app-store-download@3x.png 3x
                  "
                />
              </a>
              <a
                class="play-store"
                :href="
                  $optixConfiguration.venue.membership.app_config
                    .google_app_page
                "
                target="_blank"
                v-if="
                  $optixConfiguration.venue.membership.app_config
                    .google_app_page &&
                    (isDesktop || isAndroid)
                "
              >
                <img
                  src="@/assets/google-play-download.png"
                  srcset="
                    @/assets/google-play-download@2x.png 2x,
                    @/assets/google-play-download@3x.png 3x
                  "
                />
              </a>
            </div>
          </div>
        </template>
      </optix-content-layout>
    </optix-container>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import Steps from "@/mixins/Steps.js";
//import OptixPhoneField from "../../ui/components/OptixPhoneField";
import _ from "lodash";

export default {
  mixins: [Validate, Steps],
  data() {
    return {
      loadingData: true,
      sending: false,
      member: {},
      phone: "",
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    if (this.isInquiryMode) {
      const inquiry = {
        customInquiry: this.$optixConfiguration.signupData.customInquiry,
        selectedItem: this.$optixConfiguration.signupData.selectedItem,
        memberAccount: this.$optixConfiguration.user.lookup.member_account,
        planStartDate: this.$optixConfiguration.signupData.planStartDate,
        planDuration: this.$optixConfiguration.signupData.planDuration,
      };
      this.$optixWS
        .call(
          "POST",
          "/members/inquiry/",
          {},
          {
            member_id: this.$optixConfiguration.member.data.member_id,
            inquiry,
          }
        )
        .then((success) => {
          this.loadingData = false;
        })
        .catch((error) => {
          this.loadingData = false;
          this.$optixConfiguration.showSystemError(
            "Error updating user",
            error
          );
        });
      return;
    }

    //this.alternative.phone = this.$optixConfiguration.user.data.phone;
    this.phone = this.$optixConfiguration.user.data.phone;

    this.$optixWS
      .call(
        "GET",
        "/members/get/" + this.$optixConfiguration.user.lookup.member_account,
        {
          params: { scope: "default_card,member_plans" },
        }
      )
      .then((success) => {
        this.member = success.data.response;
        if (this.member.member_since == "Pending") {
          const variables = this.generatePayloadForSignup(
            this.$optixConfiguration.venue.venue_id,
            this.$optixConfiguration.signupData.selectedItem,
            this.$optixConfiguration.user.lookup.member_account,
            this.$optixConfiguration.signupData.planStartDate,
            this.$optixConfiguration.signupData.planDuration
          );
          this.$optixWS
            .graphQL(
              `mutation userSignupCommit(
          $organization_id: ID!
          $plan: PlanSignupInput
          $product: ProductSaleInput
          ) {
          userSignupCommit(
            source: "Web onboarding"
            organization_id: $organization_id
            plan:  $plan
            product: $product
          ) {
            account_plan {
              name
              anchor_start_timestamp
              accesses {
                description
              }
              price
              set_up_fee
              deposit
            }
            product_sale {
              name
            }
            invoices {
              invoice_due_timestamp
              subtotal
              total
              items {
                name
                description
                price
                quantity
                tax
              }
            }
          }
        }`,
              variables
            )
            .then((success) => {
              if (success.data.errors) {
                this.$optixConfiguration.showSystemError(
                  success.data.errors[0].message,
                  {},
                  success.data.errors[0].message
                );
                this.$router.push({
                  name: "PayAndConfirmPlansV2",
                });
              } else {
                this.loadingData = false;
                this.showSuccessMessage("selfSubscribe");
                // Good!
              }
            })
            .catch((error) => {
              if (error.response.data.status.code == 622) {
                this.$optixConfiguration.showSystemError(
                  "Payment unsuccessful. Please try again.",
                  {},
                  "Payment unsuccessful. Please try again."
                );
              } else {
                this.$optixConfiguration.showSystemError(
                  "Error self subscribing user",
                  error
                );
              }
              this.$router.push({
                name: "PayAndConfirmPlansV2",
              });
            });
        } else {
          // Ready to go, nothing to change for this user
          this.loadingData = false;
        }
      })
      .catch((error) => {
        this.$optixConfiguration.showSystemError(
          "Error retrieving member",
          error
        );
        this.$router.push({ name: "Lookup" });
      });
  },
  watch: {},
  computed: {
    thankyouMessage() {
      let message = "";
      if (
        this.$optixConfiguration.options.onboarding.thankyouMessage &&
        !this.isInquiryMode
      ) {
        message = this.$optixConfiguration.options.onboarding.thankyouMessage;
      } else if (
        this.$optixConfiguration.options.inquiry &&
        this.$optixConfiguration.options.inquiry.thankyouMessage &&
        this.isInquiryMode
      ) {
        message = this.$optixConfiguration.options.inquiry.thankyouMessage;
      } else {
        message = this.isInquiryMode
          ? `Thank you for your interest! A member of our team will be in touch with you.`
          : `We're looking forward to having you as a member of our space!`;
      }
      return message
        .replace(/\{email\}/, this.$optixConfiguration.user.data.email)
        .replace(/\{name\}/, this.$optixConfiguration.user.data.name);
    },
    isDesktop() {
      return !navigator.userAgent.match(/iPad|iPhone|iPod|Android/);
    },
    isIOS() {
      return navigator.userAgent.match(/iPad|iPhone|iPod/);
    },
    isAndroid() {
      return navigator.userAgent.match(/Android/);
    },
    requiresPaymentMethod() {
      return (
        this.$optixConfiguration.venue.membership.require_payment_method == 1 &&
        this.$optixConfiguration.venue.payment_gateway &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods.length >
          0
      );
    },
  },
  methods: {
    showSuccessMessage(type) {
      if (
        this.requiresPaymentMethod &&
        this.$optixConfiguration.signupData.selectedPlan &&
        this.$optixConfiguration.signupData.selectedPlan.price > 0
      ) {
        this.$optixConfiguration.showMessage(
          "Payment successful! We have emailed a confirmation to " +
            this.$optixConfiguration.user.data.email,
          "success"
        );
      } else {
        this.$optixConfiguration.showMessage(
          "Thank you! We have emailed a confirmation to " +
            this.$optixConfiguration.user.data.email,
          "success"
        );
      }
    },
    sendLink() {
      let pmValidation = [];

      pmValidation.push(this.$refs.phone.validateInfo());

      Promise.all(pmValidation)
        .then((success) => {
          this.sending = true;
          this.$optixWS
            .call(
              "POST",
              "/venues/mobile/sendAppURL/",
              {},
              {
                venue_id: this.$optixConfiguration.venue.venue_id,
                phone: this.$refs.phone.getPhoneFull(),
              }
            )
            .then((success) => {
              this.sending = false;
              this.$optixConfiguration.showMessage(
                "Link successfully sent!",
                "success"
              );
            })
            .catch((error) => {
              this.sending = false;
              this.$optixConfiguration.showSystemError(
                "Error sending sms link",
                error
              );
            });
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="stylus" scoped>
.complete-boxes>div>div {
  padding-bottom: 35px;
  text-align: center;
}

.complete-boxes>div>div:last-child {
  padding-bottom: 0px;
}

.complete-boxes>div>div:first-child {
  padding-top: 10px;
}

.phone {
  text-align: left;
}

.thank-you {
  display: flex;
  justify-content: center;
}

.thank-you>div {
  max-width: 360px;
}

h4.center {
  text-align: center;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .play-store {
    padding-left: 12px;
    padding-right: 12px;
  }

  .app-store {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>
