import { DateTime } from "luxon";
export default {
  computed: {
    timezone() {
      return this.$optixConfiguration.venue.timezone;
    },
    currencySymbol() {
      return this.$optixConfiguration.venue.currency_symbol;
    },
    /**
     * @returns {boolean}
     */
    shouldAutoSelectPlan() {
      let planFilter = this.$optixConfiguration.queryStringVars.plans;

      if (!planFilter) {
        return false;
      }

      // Auto skip plan list when filtering by single plan
      return planFilter.split(",").length === 1;
    },
    isInquiryMode() {
      return window.location.href.includes("inquiry");
    },
  },
  methods: {
    generatePayloadForSignup(
      organization_id,
      selectedItem,
      member_id,
      start_date,
      duration
    ) {
      let variables = {
        organization_id: organization_id,
      };
      if (!selectedItem) {
        return variables;
      }
      if (selectedItem.product_id) {
        variables.product = {
          product_id: selectedItem.product_id,
          account: {
            organization_id: organization_id,
            member_id: member_id,
          },
        };
      } else {
        let start_timestamp = DateTime.fromISO(start_date, {
          zone: this.timezone,
        })
          .startOf("day")
          .toSeconds();
        variables.plan = {
          payer_account: {
            organization_id: organization_id,
            member_id: member_id,
          },
          plan_template_id: selectedItem.plan_template_id,
          start_timestamp,
        };
        if (selectedItem.default_end_date == "USER_CAN_CHOOSE") {
          if (duration) {
            variables.plan.end_timestamp = DateTime.fromSeconds(
              start_timestamp,
              {
                zone: this.timezone,
              }
            )
              .plus(
                this.calculateEndDateDiff(
                  duration,
                  selectedItem.price_frequency
                )
              )
              .startOf("day")
              .minus({ second: 1 })
              .toSeconds();
          } else {
            variables.plan.no_end = true;
          }
        }
      }
      return variables;
    },
    getPeriodPlural(count, period) {
      if (!period || typeof period !== "string") {
        return period;
      }
      if (count != 1) {
        return period.toLowerCase();
      }
      if (period === "Hours") return "hour";
      if (period === "Days") return "day";
      return period.toLowerCase();
    },
    getPeriodShortName(period) {
      return (
        {
          // V1
          Month: "/mo",
          Year: "/yr",
          Once: "",
          // V2
          WEEKLY: "/week",
          BIWEEKLY: "/2 weeks",
          THIRTY_DAYS: "/30 days",
          SIXTY_DAYS: "/60 days",
          NINETY_DAYS: "/90 days",
          MONTHLY: "/month",
          QUARTERLY: "/quarter",
          BIANNUAL: "/6 months",
          ANNUAL: "/year",
        }[period] || "/period"
      );
    },
    getPeriodLongName(period) {
      return (
        {
          // V2
          WEEKLY: "every week",
          BIWEEKLY: "every 2 weeks",
          THIRTY_DAYS: "every 30 days",
          SIXTY_DAYS: "every 60 days",
          NINETY_DAYS: "every 90 days",
          MONTHLY: "every month",
          QUARTERLY: "every quarter",
          BIANNUAL: "every 6 months",
          ANNUAL: "every year",
        }[period] || ""
      );
    },
    getFrequencyLabelFor(num, frequency) {
      if (num <= 0) {
        return "No End Date";
      }

      if (num == 1) {
        return {
          // V2
          WEEKLY: "1 Week",
          BIWEEKLY: "2 Weeks",
          THIRTY_DAYS: "30 Days",
          SIXTY_DAYS: "60 Days",
          NINETY_DAYS: "90 Days",
          MONTHLY: "1 Month",
          QUARTERLY: "1 Quarter",
          BIANNUAL: "6 Months",
          ANNUAL: "1 Year",
        }[frequency];
      }

      return {
        // V2
        WEEKLY: `${num} Weeks`,
        BIWEEKLY: `${num * 2} Weeks`,
        THIRTY_DAYS: `${num * 30} Days`,
        SIXTY_DAYS: `${num * 60} Days`,
        NINETY_DAYS: `${num * 90} Days`,
        MONTHLY: `${num} Months`,
        QUARTERLY: `${num} Quarters`,
        BIANNUAL: `${num * 6} Months`,
        ANNUAL: `${num} Years`,
      }[frequency];
    },
    calculateEndDateDiff(num, frequency) {
      return {
        // V2
        WEEKLY: { week: num },
        BIWEEKLY: { week: num * 2 },
        THIRTY_DAYS: { day: num * 30 },
        SIXTY_DAYS: { day: num * 60 },
        NINETY_DAYS: { day: num * 90 },
        MONTHLY: { month: num },
        QUARTERLY: { month: num * 3 },
        BIANNUAL: { month: num * 6 },
        ANNUAL: { year: num },
      }[frequency];
    },
    getAccessDescription(access, plan) {
      const credit = access.credit;
      const discount = access.member_discount;
      const units = this.getPeriodPlural(access.credit, access.unit);
      const resourceOrType =
        access.workspace_name || access.workspace_type.toLowerCase();
      const mentionResourceOrType = access.unit && access.unit !== "Days";
      const location = access.location_name || "any location";

      let result;

      if (!credit && discount) {
        result = `${discount}% discounted rate`;

        if (mentionResourceOrType) {
          result += ` on ${resourceOrType}`;
        }

        result += ` at ${location}`;
      } else {
        result = `${credit} ${units}`;

        if (plan.period !== "Once") {
          result += " per " + plan.period.toLowerCase();
        }

        if (mentionResourceOrType) {
          result += ` for ${resourceOrType}`;
        }

        result += ` at ${location}`;

        if (discount) {
          result += `, then ${discount}% discounted rate after plan allowance has been fully consumed`;
        }
      }

      return result;
    },
    formatNumber(value) {
      return Number(value)
        .toFixed(2)
        .replace(/\.00$/, "");
    },
    taxName(inclusive) {
      let result =
        this.$optixConfiguration.venue.membership.custom_tax_name || "Tax";
      if (inclusive) {
        result = "Includes " + (result === "Tax" ? "tax" : result);
      }
      return result;
    },
  },
};
