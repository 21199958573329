<template>
  <div class="footer">
    <div class="footer-inner optix-option-navBar" :class="baseClass">
      <v-layout align-center row fill-height>
        <v-flex class="text-xs-left left-nav">
          <slot name="left"><div></div></slot>
        </v-flex>
        <v-flex class="text-xs-center center-nav">
          <slot name="center"></slot>
        </v-flex>
        <v-flex class="text-xs-right right-nav">
          <slot name="right"></slot>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
export default {
  name: "optix-footer-navigation",
  computed: {
    baseClass() {
      let shadowLevel = this.$optixConfiguration.options.navBarShadow
        .toString()
        .replace(/px/, "");
      if (shadowLevel > 0) {
        return "elevation-" + shadowLevel;
      } else {
        return "elevation-0  top-divider";
      }
    },
  },
  methods: {},
};
</script>
<style lang="stylus" scoped>
.left-nav {
  flex-basis: 30%;
  padding-left: 16px;
}

.center-nav {
  flex-basis: 30%;
  flex-grow: 2;
  align-content: center;
  justify-content: center;
}

.right-nav {
  flex-basis: 30%;
  padding-right: 16px;
}

@media (max-width: 900px) {
  .left-nav {
    padding-left: 8px;
  }
  .right-nav {
    padding-right: 8px;
  }
}
</style>
