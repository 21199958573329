import forEach from "lodash/forEach";
import zxcvbn from "zxcvbn";

export default {
  rules: {
    email: (email) =>
      !email.match(/^.+@[^.]+(\.[^.]+)+$/) ? "Please enter a valid email" : "",
    url: (url) =>
      !url.match(/^https?:\/\//) ? "Please enter a valid URL" : "",
    resetCode: (resetCode) =>
      !resetCode.match(/^.{5}$/) ? "Please enter a valid reset code" : "",
    password: (password) =>{
      // !password.match(/^.{8}/) ? "Password must be at least 8 characters" : "",
      if (typeof password != "string" || !password.match(/^.{8}/)) {
        return "Password must be at least 8 characters";
      }
      const str = zxcvbn(password);
      if (str.score <= 2) {
        return str.feedback.warning || str.feedback.suggestions[0] || "Password is weak or common";
      }
      return "";
    },
      
    phone: (value) =>
      typeof value != "string" ||
      !value.match(/^\+?[0-9\. \)\(-)]+$/) ||
      !value.replace(/[^0-9]/, "").match(/^[0-9]{8}/)
        ? "Please enter a valid phone number"
        : "",
    number: (value) =>
      typeof value != "string" || !value.match(/^-?[0-9]+(\.[0-9]+)?$/)
        ? "Please type a valid number"
        : "",
    min: (min) => (value) =>
      typeof value != "string" || value.length < min
        ? "Requires at least " + min + " characters"
        : "",
    max: (max) => (value) =>
      typeof value != "string" || value.length > max
        ? "Maximum " + max + " characters"
        : "",
    required: (value) => {
      if (
        value !== null &&
        value !== undefined &&
        ((typeof value == "string" && value.replace(/ /, "").length > 0) ||
          (typeof value == "object" && Object.keys(value).length > 0))
      ) {
        return "";
      } else {
        return "This field is required";
      }
    },
    optional: (rules) => (value) => {
      // Set a field as optional, if any value is provided must follow the rules provided
      if (
        value == undefined ||
        value == "" ||
        value == 0 ||
        value.length == 0
      ) {
        return "";
      } else {
        let rule_error = "";
        forEach(rules, (rule) => {
          let error = rule(value);
          if (typeof error === "string" && error != "") {
            rule_error = error;
            return false;
          }
        });
        return rule_error;
      }
    },
    all: (rules) => (value) => {
      // Test if all rules matches, if any rule fail, returns the first fail
      let rule_error = "";
      forEach(rules, (rule) => {
        let error = rule(value);
        if (typeof error === "string" && error != "") {
          rule_error = error;
          return false;
        }
      });
      return rule_error;
    },
  },
  data() {
    return {
      errors: {},
      validForm: true,
    };
  },
  created() {},
  methods: {
    // Add an error to a field
    addError(field, message) {
      if (this.errors[field]) {
        this.errors[field].push(message);
      } else {
        this.$set(this.errors, field, [message]);
      }
    },
    // Validate the data provided against the rules
    // Data must be an object, rules will be validated by keys
    // Example: rules = {mydata: [myvalidationfunction,othervalidationforsamefield]}
    // You can provide extra promises to complement the form validation
    validateForm(data, rules, extra_promises) {
      this.resetForm();
      let form_validation_promise = new Promise((accept, reject) => {
        forEach(rules, (fieldRules, fieldName) => {
          fieldRules.map((rule) => {
            let error = rule(data[fieldName]);
            if (typeof error === "string" && error != "") {
              this.addError(fieldName, error);
            }
          });
        });

        if (Object.keys(this.errors).length) {
          this.validForm = false;
          reject(this.errors);
        }
        accept({});
      });
      if (extra_promises && extra_promises.length) {
        return Promise.all([form_validation_promise].concat(extra_promises));
      } else {
        return Promise.all([form_validation_promise]);
      }
    },
    // Reset form errors
    resetForm() {
      this.errors = {};
      this.validForm = true;
    },
  },
};
