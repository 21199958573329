<template>
  <div>
    <v-layout row align-center>
      <v-flex class="code">
        <optix-text-field
          ref="code"
          pattern="\d*"
          label="Country code"
          v-model="code"
          prefix="+"
          class="pl-1"
          @keyup="cleanCode"
          :error="errors.code && errors.code != ''"
        />
      </v-flex>
      <v-flex class="phone">
        <optix-text-field
          ref="phone"
          label="Phone number"
          class="pl-1"
          v-model="phone"
          hint="You will receive an SMS with a link to download the app"
          persistent-hint
          @keyup="cleanPhone"
          :error="errors.phone && errors.phone != ''"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import jsonp from "jsonp";
import qs from "qs";
import forEach from "lodash/forEach";

export default {
  mixins: [Validate],
  name: "optix-phone-field",
  props: {},
  data() {
    return {
      code: "",
      phone: "",
    };
  },
  watch: {},
  methods: {
    validateInfo() {
      return this.validateForm(this, {
        code: [Validate.rules.required],
        phone: [Validate.rules.required],
      });
    },
    getPhoneFull() {
      return "+" + this.code + this.phone;
    },
    cleanCode(event) {
      let clean = this.code.toString().replace(/[^0-9]+/, "");
      if (clean != this.code) {
        this.code = clean;
      }
      if (event.key != "Tab" && event.key != "Shift" && clean.length == 3) {
        this.$refs.phone.focus();
      }
    },
    cleanPhone(event) {
      let clean = this.phone.toString().replace(/[^0-9]+/, "");
      if (clean != this.phone) {
        this.phone = clean;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.slash {
    flex-basis: 10px;
    max-width: 10px;
}

.oneline {
//flex-basis: 100%;
}

.code {
    flex-basis: 110px;
    max-width: 110px;
}

.date {
    flex-basis: 80px;
    max-width: 80px;
}
</style>
