<template>
  <div>
    <optix-header>
      <span v-if="hasSelectedItem || !requiresPaymentMethod">
        Review and confirm
      </span>
      <span v-else-if="requiresPaymentMethod"> Payment method </span>
      <span v-else> Review </span>
    </optix-header>
    <optix-container>
      <optix-content-layout v-if="loadingData">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else style="padding-top: 0px">
        <optix-form ref="form">
          <div v-if="hasSelectedItem" :class="{ 'pb-5': this.invoices.length }">
            <template
              v-if="selectedItem.price > 0 && selectedItem.plan_template_id"
            >
              <p>
                Details of your first payment are shown below, regular payments
                of

                {{ currencySymbol
                }}{{ selectedItem.price.toString().replace(/\.00$/, "") }}
                {{ getPeriodLongName(selectedItem.price_frequency) }} will begin
                {{ startBillingDate }}.
                <strong v-if="selectedItem.free_trial_days"
                  >You will get {{ selectedItem.free_trial_days }} days worth of
                  the allowance included in your trial.</strong
                >
              </p>
            </template>
            <h4 class="mb-4 pt-4" v-if="invoices.length == 0">
              {{ selectedItem.plan_template_id ? "Plan" : "Pass" }} summary
            </h4>
            <h4 class="mb-2 pt-4" v-else>Due {{ dueDate }}</h4>

            <template v-if="invoices.length">
              <div
                class="pb-3 pt-3"
                v-for="(item, index) in invoices[0].items"
                :key="index"
                :class="{ 'bottom-divider': true }"
              >
                <div class="side-by-side spread item">
                  <p>{{ item.name }}</p>
                  <p class="ml-3">
                    {{ currencySymbol }}{{ formatNumber(item.price) }}
                  </p>
                </div>
                <p class="item-description">{{ item.description }}</p>

                <template
                  v-if="
                    ((item.name && item.name.match(/^Plan/)) ||
                      selectedItem.product_id) &&
                    ![
                      'ACCOUNT_PLAN_DEPOSIT',
                      'ACCOUNT_PLAN_SETUP',
                      'SUBSCRIPTION_DEPOSIT',
                      'SUBSCRIPTION_SETUP',
                      'TRANSACTION_FEE',
                    ].includes(item.origin_type)
                  "
                >
                  <div
                    class="plan-access pb-2"
                    v-if="
                      (openDetails || !requiresPaymentMethod) &&
                      selectedItem.accesses &&
                      selectedItem.accesses.length > 0
                    "
                  >
                    <ol>
                      <li
                        v-for="(access, index) in selectedItem.accesses"
                        class="plan-access grey--text text--darken-1"
                        :key="index"
                      >
                        <div class="plan-icon"><v-icon>done</v-icon></div>
                        <span>{{ access.description }}</span>
                      </li>
                    </ol>
                  </div>

                  <a
                    @click="openDetails = false"
                    v-if="
                      (openDetails || !requiresPaymentMethod) &&
                      selectedItem.accesses &&
                      selectedItem.accesses.length > 0
                    "
                    >Hide details</a
                  >
                  <a
                    @click="openDetails = true"
                    v-if="
                      !(openDetails || !requiresPaymentMethod) &&
                      selectedItem.accesses &&
                      selectedItem.accesses.length > 0
                    "
                    >View details</a
                  >
                </template>
              </div>
            </template>
            <!-- FREE --->
            <template v-else>
              <div
                class="pb-3"
                :class="{ 'bottom-divider': invoices.length > 0 }"
              >
                <div class="side-by-side spread item">
                  <p>{{ selectedItem.name }}</p>
                  <p class="ml-3">Free</p>
                </div>
                <p class="item-description">{{ selectedItem.description }}</p>

                <div
                  class="plan-access pb-2"
                  v-if="
                    openDetails &&
                    selectedItem.accesses &&
                    selectedItem.accesses.length > 0
                  "
                >
                  <ol>
                    <li
                      v-for="(access, index) in selectedItem.accesses"
                      class="plan-access grey--text text--darken-1"
                      :key="index"
                    >
                      <div class="plan-icon"><v-icon>done</v-icon></div>
                      <span>{{ access.description }}</span>
                    </li>
                  </ol>
                </div>

                <a
                  @click="openDetails = false"
                  v-if="
                    openDetails &&
                    selectedItem.accesses &&
                    selectedItem.accesses.length > 0
                  "
                  >Hide details</a
                >
                <a
                  @click="openDetails = true"
                  v-if="
                    !openDetails &&
                    selectedItem.accesses &&
                    selectedItem.accesses.length > 0
                  "
                  >View details</a
                >
              </div>
            </template>
            <div class="summary pt-3" v-if="invoices.length > 0">
              <div class="summary-table">
                <div class="side-by-side spread" v-if="realSubtotal > 0">
                  <p>Subtotal</p>
                  <p>{{ currencySymbol }}{{ realSubtotal }}</p>
                </div>
                <div
                  class="side-by-side spread"
                  v-for="(item, index) in taxSummary"
                  :key="index"
                  v-if="item.total_tax > 0"
                >
                  <p>{{ item.tax_label }}</p>
                  <p>{{ currencySymbol }}{{ item.total_tax }}</p>
                </div>
                <div class="side-by-side spread total" v-if="realTotal > 0">
                  <p>Total</p>
                  <p>{{ currencySymbol }}{{ realTotal }}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="requiresPaymentMethod">
            <h4 class="mb-4">Payment method</h4>

            <optix-radio-group
              v-model="paymentMethod"
              :mandatory="true"
              class="payment-methods"
            >
              <payment-option
                v-if="!paymentToken"
                class="mb-2"
                name="Credit card"
                selected-value="cc"
                :open="paymentMethod === 'cc'"
              >
                <optix-credit-card
                  ref="pm_cc"
                  :processor="processor"
                  v-model="paymentToken"
                />
              </payment-option>

              <payment-option
                v-if="!paymentToken && paymentMethods.length > 1"
                class="mb-2"
                name="Bank account"
                selected-value="ba"
                :open="paymentMethod === 'ba'"
              >
                <optix-bank-account
                  ref="pm_ba"
                  :processor="processor"
                  v-model="paymentToken"
                />
              </payment-option>
              <template v-for="pm in additionalPaymentMethods">
                <payment-option
                  :key="pm.key"
                  :name="pm.name"
                  v-if="!paymentToken || paymentMethod === pm.key"
                  class="mb-2"
                  :selected-value="pm.key"
                  :open="paymentMethod === pm.key"
                >
                  <optix-additional-payment-method
                    :pm="pm"
                    v-model="paymentToken"
                  />
                </payment-option>
              </template>
            </optix-radio-group>
          </div>
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" @click="back" flat>Back</optix-btn>
      <optix-dot-progress slot="center" />
      <optix-btn slot="right" @click="next" :loading="nextLoading"
        >Confirm</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Steps from "@/mixins/Steps.js";
import { DateTime } from "luxon";
import PaymentOption from "../../ui/components/PaymentOption.vue";

export default {
  components: { PaymentOption },
  mixins: [Steps],
  data() {
    return {
      loadingData: false,
      nextLoading: false,
      paymentMethod: "cc",
      paymentToken: {},
      additionalPaymentMethods: [],
      invoices: [],
      plan: {},
      sale: {},
      openDetails: false,
      member: {},
    };
  },
  computed: {
    requiresPaymentMethod() {
      if (!this.paymentMethods.length) {
        return false;
      }

      return (
        this.$optixConfiguration.venue.membership.require_payment_method == 1 ||
        (this.$optixConfiguration.venue.membership
          .require_payment_method_on_charge == 1 &&
          this.invoices.length > 0)
      );
    },
    processor() {
      return this.$optixConfiguration.venue.payment_gateway.processor_id;
    },
    dueDate() {
      if (this.invoices.length) {
        return DateTime.fromSeconds(this.invoices[0].invoice_due_timestamp, {
          zone: this.timezone,
        }).toLocaleString(DateTime.DATE_MED);
      }
      return "";
    },
    startBillingDate() {
      if (this.plan.anchor_start_timestamp) {
        return DateTime.fromSeconds(this.plan.anchor_start_timestamp, {
          zone: this.timezone,
        }).toLocaleString(DateTime.DATE_MED);
      }
      return "";
    },
    hasSelectedItem() {
      return !!this.$optixConfiguration.signupData.selectedItem;
    },
    selectedItem() {
      return this.$optixConfiguration.signupData.selectedItem;
    },
    chargeInvoice() {
      // Exclude Spreedly bank accounts ("ba"), allow credit cards and Stripe bank accounts
      return this.invoices.length > 0 && this.paymentMethod !== "ba";
    },
    paymentMethods() {
      const payment_methods = [];
      if (
        this.$optixConfiguration.venue.payment_gateway &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods
      ) {
        const available_pm =
          this.$optixConfiguration.venue.payment_gateway.payment_methods;
        if (available_pm.indexOf("credit_card") != -1) {
          payment_methods.push({ text: "Credit card", value: "cc" });
        }
        if (available_pm.indexOf("bank_account") != -1) {
          payment_methods.push({ text: "Bank account", value: "ba" });
        }
        // Preselect the only option
        if (payment_methods.length === 1) {
          this.paymentMethod = payment_methods[0].value;
        }
      }
      return payment_methods;
    },
    realSubtotal() {
      return this.getInvoiceField("subtotal");
    },
    realTotal() {
      return this.getInvoiceField("total");
    },
    taxSummary() {
      if (!this.invoices.length) {
        return [];
      }

      return this.invoices[0].tax_summary.map((item) => {
        const name = this.taxName(item.tax_mode === "INCLUSIVE");
        item.tax_label = `${name} @ ${item.tax_rate}% on ${this.currencySymbol}${item.total_amount}`;
        return item;
      });
    },
  },
  mounted() {
    this.loadingData = true;
    this.paymentToken = null;

    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    const member_promise = this.$optixWS.call(
      "GET",
      "/members/get/" + this.$optixConfiguration.user.lookup.member_account,
      {
        params: { scope: "default_card,member_plans" },
      }
    );

    if (
      this.$optixConfiguration.venue.membership.require_member_plan == 1 &&
      !this.$optixConfiguration.signupData.selectedItem
    ) {
      this.$router.push({ name: "PlanList" });
      return;
    }

    const promises = [member_promise];

    const promise_check_member = this.$optixWS.call(
      "POST",
      "/members/mobile/lookup/",
      {},
      {
        venue_id: this.$optixConfiguration.venue.venue_id,
        email: this.$optixConfiguration.user.data.email,
        member_type: "onboarding_check",
      }
    );
    promises.push(promise_check_member);

    if (this.hasSelectedItem) {
      promises.push(this.draftUserSignup());

      const promise_retainable_pm = this.$optixWS.graphQL(
        `query retainablePaymentMethods($organization_id: ID!) {
          retainablePaymentMethods(organization_id:$organization_id){
            name
            payment_method_type
            url
            open_using_external_app
          }
        }`,
        {
          organization_id: this.$optixConfiguration.venue.venue_id,
        }
      );
      promises.push(promise_retainable_pm);
    } else {
      promises.push(null);
    }

    Promise.all(promises)
      .then((success) => {
        this.loadingData = false;
        if (success[1].data.response.member_status == "1") {
          this.$optixConfiguration.showMessage(
            "You are already a member of this venue, enter your email address to continue to download your app, or enter a new email address to sign up again",
            "info",
            10000
          );
          this.$router.push({ name: "Lookup" });
          return;
        }
        if (this.hasSelectedItem && success[2]) {
          this.handleDraftedUserSignup(success[2]);
        }
        this.member = success[0].data.response;
        // Keep additional payment methods separately
        if (success[3]) {
          this.additionalPaymentMethods =
            success[3].data.data.retainablePaymentMethods
              .filter((pm) => pm.open_using_external_app)
              .map((pm) => ({
                ...pm,
                key: this.getPaymentMethodKey(pm),
              }));
        }
      })
      .catch((error) => {
        this.$optixConfiguration.showSystemError("Lookup error", error);
      });
  },
  watch: {
    async paymentMethod(value, oldValue) {
      if (value !== oldValue) {
        this.handleDraftedUserSignup(await this.draftUserSignup());
      }
    },
  },
  methods: {
    getInvoiceField(field) {
      const value = this.invoices.length ? this.invoices[0][field] : 0;
      return this.formatNumber(value);
    },
    draftUserSignup() {
      const variables = this.generatePayloadForSignup(
        this.$optixConfiguration.venue.venue_id,
        this.selectedItem,
        this.$optixConfiguration.user.lookup.member_account,
        this.$optixConfiguration.signupData.planStartDate,
        this.$optixConfiguration.signupData.planDuration
      );
      if (!this.requiresPaymentMethod) {
        variables.payment_method_type = null;
      } else if (this.paymentMethod === "cc") {
        // Stripe/Spreedly credit card
        variables.payment_method_type = "credit_card";
      } else if (this.paymentMethod === "ba") {
        // Spreedly bank account
        variables.payment_method_type = "bank_account";
      } else if (this.paymentMethod.length > 0) {
        // Stripe bank account
        variables.payment_method_type = this.getPaymentMethodType(
          this.paymentMethod
        );
      }
      return this.$optixWS.graphQL(
        `query userSignupDraft(
          $organization_id: ID!
          $plan: PlanSignupInput
          $product: ProductSaleInput
          $payment_method_type: String
        ) {
          userSignupDraft(
            source: "Web onboarding"
            organization_id: $organization_id
            plan: $plan
            product: $product
            payment_method_type: $payment_method_type
          ) {
            account_plan {
              name
              anchor_start_timestamp
              accesses {
                description
              }
              price
              set_up_fee
              deposit
            }
            product_sale {
              name
            }
            invoices {
              invoice_due_timestamp
              subtotal
              total
              items {
                name
                description
                price
                quantity
                origin_type
              }
              tax_summary {
                tax_mode
                tax_rate
                total_amount
                total_tax
              }
            }
          }
        }`,
        variables
      );
    },
    handleDraftedUserSignup(response) {
      this.invoices = response.data.data.userSignupDraft.invoices;
      this.plan = response.data.data.userSignupDraft.account_plan;
      this.sale = response.data.data.userSignupDraft.product_sale;
    },
    next() {
      this.nextLoading = true;
      if (this.requiresPaymentMethod) {
        const pmValidation = [];

        if (this.paymentMethod === "ba") {
          pmValidation.push(this.$refs.pm_ba.validateInfo());
        } else if (this.paymentMethod === "cc") {
          pmValidation.push(this.$refs.pm_cc.validateInfo());
        } else if (this.paymentMethod.length > 0 && !this.paymentToken) {
          this.$optixConfiguration.showMessage(
            "Please connect the account to continue",
            "error"
          );
          return;
        } else if (
          this.paymentMethod.length > 0 &&
          this.paymentToken &&
          this.paymentToken.pm_id
        ) {
          this.$router.push({ name: "Complete" });
          return;
        }

        Promise.all(pmValidation)
          .then((success) => {
            const pmTokenCreation = [];
            if (this.paymentMethod === "ba") {
              pmTokenCreation.push(this.$refs.pm_ba.createToken());
            }
            if (this.paymentMethod === "cc") {
              pmTokenCreation.push(this.$refs.pm_cc.createToken());
            }
            Promise.all(pmTokenCreation)
              .then((success) => {
                const requestPayload = success[0];
                this.$optixWS
                  .call("POST", "/payments/add/", {}, requestPayload)
                  .then((success) => {
                    this.$router.push({ name: "Complete" });
                  })
                  .catch((error) => {
                    this.$optixConfiguration.showSystemError(
                      "Cannot add payment method",
                      error,
                      "Error adding payment method, please try again."
                    );
                    this.nextLoading = false;
                    this.$refs.form.focusErrors();
                  });
              })
              .catch((e) => {
                if (e.messages && e.messages.length) {
                  this.$optixConfiguration.showMessage(
                    e.messages.join(", "),
                    "error"
                  );
                }
                this.nextLoading = false;
                this.$refs.form.focusErrors();
              });
          })
          .catch((e) => {
            this.nextLoading = false;
            this.$refs.form.focusErrors();
          });
      } else {
        this.$router.push({ name: "Complete" });
      }
    },
    back() {
      if (
        this.$optixConfiguration.venue.membership.require_member_plan ||
        this.selectedItem
      ) {
        if (!this.selectedItem || this.selectedItem.product_id) {
          this.$router.push({ name: "PlanList" });
        } else {
          this.$router.push({ name: "PlanOptions" });
        }
      } else {
        this.$router.push({ name: "Details" });
      }
    },
    getPaymentMethodKey(pm) {
      // payment_method_type is not unique, e.g. acss_debit
      return pm.payment_method_type + "|" + pm.name;
    },
    getPaymentMethodType(pmKey) {
      // pmKey examples: "acss_debit|Personal bank account", "acss_debit|Business bank account"
      return pmKey.split("|")[0];
    },
  },
};
</script>

<style lang="stylus" scoped>
.side-by-side {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &.spread {
    justify-content: space-between;
  }
}

.item {
  font-size: 18px;

  p {
    margin-bottom: 0;
  }
}

.item-description {
  opacity: 0.6;
  margin: 0 0 6px;
}

.plan-access {
  list-style: none;
  list-style-position: outside;

  ol {
    padding-left: 32px;

    li {
      position: relative;
      padding: 4px 0;

      .plan-icon {
        display: inline-block;
        position: absolute;
        left: -32px;
      }
    }
  }
}

.summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;

  .summary-table {
    max-width: 75%;

    p {
      margin-bottom: 10px;
      margin-left: 15px;
    }

    .total {
      font-weight: bold;
    }
  }
}
.payment-methods {
  width: 100%;
  ::v-deep .v-input__control {
    width: 100%;
  }
}
</style>
