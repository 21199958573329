<template>
  <div>
    <optix-header>{{ navBarTitle }}</optix-header>
    <optix-container>
      <optix-content-layout>
        <optix-form ref="form">
          <div v-if="canChooseStartDate">
            <optix-date
              v-model="$optixConfiguration.signupData.planStartDate"
              label="Start date"
              hint="The date you would like your plan to start"
              :min="minDate"
            />
          </div>
          <div v-else>
            <h4>Start date</h4>
            <span class="subheading">{{ formattedStartDate }}</span>
          </div>

          <div v-if="endDays > 0" class="mt-5">
            <h4>Duration</h4>
            <span class="subheading">{{ endDays }} days</span>
            <p class="mt-3 grey--text">
              The length of time your plan will be active. This plan has a set
              duration that cannot be changed.
            </p>
          </div>
          <div v-else-if="forcedNoEnd" class="mt-5">
            <!-- Mobile devices don't show anything in this case -->
            <!--h4>Duration</h4>
                        <span class="subheading">No end date</span>
            <p class="mt-3 grey--text">The length of time your plan will be active. This plan has a set duration that cannot be changed.</p-->
          </div>
          <div v-else class="mt-5">
            <optix-select
              label="Duration"
              :items="endOptions"
              hint="The length of time your plan will be active"
              persistent-hint
              v-model="$optixConfiguration.signupData.planDuration"
            />
          </div>
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" @click="back" flat>Back</optix-btn>
      <optix-dot-progress slot="center" />
      <optix-btn slot="right" @click="next" :loading="nextLoading"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Steps from "@/mixins/Steps.js";

export default {
  mixins: [Steps],
  data() {
    return {
      nextLoading: false,
      minDate: new Date().toISOString().substr(0, 10),
    };
  },
  mounted() {
    if (
      !this.$optixConfiguration.signupData.selectedPlanId &&
      !this.$optixConfiguration.signupData.selectedItem
    ) {
      this.$router.push({ name: "PlanList" });
      return;
    }
    // Skip this selection if a product (v2)
    if (
      this.$optixConfiguration.signupData.selectedItem &&
      this.$optixConfiguration.signupData.selectedItem.product_id
    ) {
      this.$router.push({
        name: "PayAndConfirmPlansV2",
      });
      return;
    }
    if (!this.$optixConfiguration.signupData.planStartDate) {
      this.$optixConfiguration.signupData.planStartDate = new Date()
        .toISOString()
        .substr(0, 10);
    }
    if (!this.$optixConfiguration.signupData.planDuration) {
      if (!this.canChooseDuration) {
        this.$optixConfiguration.signupData.planDuration = 0; // not used
      } else if (this.forcedNoEnd) {
        this.$optixConfiguration.signupData.planDuration = -1;
      }
    }
  },
  computed: {
    forcedNoEnd() {
      // V2
      if (this.$optixConfiguration.signupData.selectedItem) {
        return (
          this.$optixConfiguration.signupData.selectedItem.default_end_date ==
          "NEVER"
        );
      }
    },
    endDays() {
      if (this.$optixConfiguration.signupData.selectedItem) {
        return this.$optixConfiguration.signupData.selectedItem
          .default_end_date_days;
      }
    },
    canChooseStartDate() {
      return this.$optixConfiguration.venue.membership.plans_start_today == 0;
    },
    canChooseDuration() {
      if (this.$optixConfiguration.signupData.selectedItem) {
        return (
          this.$optixConfiguration.signupData.selectedItem.default_end_date ==
          "USER_CAN_CHOOSE"
        );
      }
    },
    formattedStartDate() {
      if (this.$optixConfiguration.signupData.planStartDate) {
        let parts = this.$optixConfiguration.signupData.planStartDate.split(
          "-"
        );
        return new Date(parts[0], parts[1] - 1, parts[2]).toLocaleDateString();
      }
      return "";
    },
    endOptions() {
      let options = [];
      if (!this.$optixConfiguration.signupData.selectedItem.price_frequency) {
        return options;
      }
      for (let month = 0; month < 13; month++) {
        options.push({
          text: this.getFrequencyLabelFor(
            month,
            this.$optixConfiguration.signupData.selectedItem.price_frequency
          ),
          value: month,
        });
      }
      return options;
    },
    navBarTitle() {
      if (!this.canChooseStartDate && !this.canChooseDuration) {
        return "Review your plan details";
      }
      if (!this.canChooseDuration) {
        return this.isInquiryMode
          ? "Select your ideal start date"
          : "Select your plan's start date";
      }
      return "Select your plan's duration";
    },
  },
  watch: {},
  methods: {
    next() {
      this.$router.push({
        name: this.isInquiryMode ? "Complete" : "PayAndConfirmPlansV2",
      });
    },
    back() {
      if (this.shouldAutoSelectPlan) {
        this.$router.push({ name: "Profile" });
        return;
      }

      this.$router.push({ name: "PlanList" });
    },
  },
};
</script>
